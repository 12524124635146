import React, { useState, useEffect } from 'react';
import {FaBars, FaCircle, FaPhone, FaPhoneAlt, FaUser} from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';
import { Button } from '../ui/Button';
import { Select } from '../ui/Select';
import { AssignDriver, CancelShipment } from '../../api/Shipment';
import { GetDrivers } from '../../api/User';
import { useNavigate } from "react-router-dom";
import { InputWithLabel } from '../ui/InputWithLabel';



export const CancelShipmentModal = ({visible, setVisible, shipmentId, userId}) => {

    const [reasonId, setReasonId] = useState(1);
    const [comment, setComment] = useState('');
    const navigate = useNavigate();
    let location = useLocation();

    useEffect(() => {
        console.log(shipmentId)
    }, [shipmentId])

    useEffect(() => {
        if (visible === true){
            setReasonId(1);
            setComment('');
        }
            
    }, [visible])

    const Cancel = async () => {
        const res = await CancelShipment(reasonId, shipmentId, comment, userId);
        if (res)
            if (res === 1){
                setVisible(false);
                navigate(location.pathname + location.search);
            } 
    }

    if (visible == false)
        return null;

    return (
        <div onClick={() => setVisible(false)} className='modal'>
            <div onClick={(e) => {e.stopPropagation()}} className='modal-container-driver-picker h-260'>
                <div className='driver-picker'>
                    <div className='title'>Cancel shipment {shipmentId}</div>
                    <hr />
                    <Select value={reasonId} onChange={setReasonId} 
                        data={Object.entries({
                            "1" : 'Used the services of another company',
                            "2" : 'The client changed his mind',
                            "3" : 'The client did not have time today', 
                            "4" : 'The driver didn\'t have time',
                            "5" : 'Other'
                        })}
                        placeholderFirst={false}
                        label='Reason'
                    />
                    <InputWithLabel
                        label='Comment'
                        req={true}
                        value={comment}
                        onChange={setComment}
                    />
                    <Button onClick={Cancel} className='btn bg-red'>
                        Cancel
                    </Button>
                    <hr />
                    <Button onClick={() => setVisible(false)} className='btn bg-grey ml-auto flex'>
                        Close
                    </Button>
                </div>
            </div>
        </div>
    );
}