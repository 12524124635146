import React, { forwardRef, useContext, useEffect, useState } from 'react';
import {Bars, FaCalendarAlt, FaClock, FaFileSignature, FaIdCard, FaPencilAlt, FaPlus, FaShieldAlt, FaTrash} from 'react-icons/fa';
import { CreatingClient, GetAddresses, GetContactPersons, GetPromoOpo, SearchClientByPhone } from '../../api/Client';
import { LoadingContext, UserContext } from '../../context';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button } from '../../components/ui/Button';
import { InputWithLabel } from '../../components/ui/InputWithLabel';
import { Select } from '../../components/ui/Select';
import { Input } from '../../components/ui/Input';
import { InputRadioBox } from '../../components/ui/InputRadioBox';
import { InputLabelAndChildren } from '../../components/ui/InputLabelAndChildren';
import { compareAsc, format } from 'date-fns'

import "react-datepicker/dist/react-datepicker.css";
import { CreateNewShipment, GetCountries } from '../../api/Shipment';
import { CreateClientModal } from '../../components/modal/CreateClientModal';
import { CreateAddressModal } from '../../components/modal/CreateAddressModal';
import { CreateContactPersonModal } from '../../components/modal/CreateContactPersonModal';
import { Content } from '../../components/modal/Content';
import DatePicker, { registerLocale } from "react-datepicker";
import enGb from 'date-fns/locale/en-GB';
registerLocale('enGb', enGb)

export const CreateShipment = () => {
    const [phone, setPhone] = useState('');
    const [clients, setClients] = useState([]);
    const [newClient, setNewClient] = useState(false);
    const [chosenClient, setChosenClient] = useState(0);
    const [pickupAddresses, setPickupAddresses] = useState([]);
    const [chosenAddress, setChosenAddress] = useState(0);
    const [newAddress, setNewAddress] = useState(false);
    const [showMore , setShowMore] = useState(true);
    const [date, setDate] = useState(new Date());
    const [timeFrom, setTimeFrom] = useState('');
    const [timeTo, setTimeTo] = useState('');
    const [description, setDescription] = useState('');
    const [promoOne, setPromoOne] = useState(false);
    const [isEnabledOpo, setEnabledOpo] = useState(true);
    const [packages, setPackages] = useState([]);
    const [deliveryAddresses, setDeliveryAddresses] = useState([]);
    const [contactPersons, setContactPersons] = useState([]);
    const [firstPackage, setFirstPackage] = useState(true);
    const [countries, setCountries] = useState([]);
    const [createClient, setCreateClient] = useState(false);
    const [createAddress, setCreateAddress] = useState(false);
    const [addressType, setAddressType] = useState('');
    const [createContactPerson, setCreateContactPerson] = useState(false);
    const [chosenPackage, setChosenPackage] = useState(-1);
    const [content, setContent] = useState(false);
    const [editAddress, setEditAddress] = useState({});
    const [editPerson, setEditPerson] = useState({});

    const {user} = useContext(UserContext);
    const {setLoading} = useContext(LoadingContext);
    let location = useLocation();
    const navigate = useNavigate();

    const CustomButtonDate = forwardRef(({ onClick }, ref) => (
        <button type='button' className='btn-calendar' onClick={onClick} ref={ref}>
            <FaCalendarAlt color='#555'/>
        </button>
    ));
    const CustomButtonTime = forwardRef(({ onClick }, ref) => (
        <button type='button' className='btn-calendar' onClick={onClick} ref={ref}>
            <FaClock color='#555'/>
        </button>
    ));

    const createShipment = async(e) => {
        e.preventDefault();
        if (chosenClient > 0){
            var data = {
                'Shipment[shipper]': chosenClient,
                'Shipment[address_from]': chosenAddress > 0 ? chosenAddress : '',
                'Shipment[pickup_date]': format(date, 'yyyy-MM-dd'),
                'Shipment[pickup_time_from]': timeFrom !== '' ? format(timeFrom, 'HH:mm') : '',
                'Shipment[pickup_time_to]': timeTo !== '' ? format(timeTo, 'HH:mm') : '',
                'Shipment[description]': description > 0 ? description : '',
                'Shipment[total_pkgs]': packages.length,
                'Shipment[one_plus_one_action]': promoOne === true ? 1 : 0,
                user_id: user.id,
            };
            if (packages.length > 0)
                packages.forEach((item, index) => {
                    Object.assign(data, {
                        [`Package[${index + 1}][address_to]`]: item.deliveryAddress,
                        [`Package[${index + 1}][consignee]`]: item.contactPerson,
                        [`Package[${index + 1}][package_type]`]: item.packageType,
                        [`Package[${index + 1}][content_price]`]: item.contentPrice,
                        [`Package[${index + 1}][content]`]: item.content,
                        [`Package[${index + 1}][gift]`]: item.gift,
                        [`Package[${index + 1}][empty]`]: item.empty,
                        [`Package[${index + 1}][chosen_country]`]: item.chosenCountry,
                    });
                });
            //console.log(data)
            const resp = await CreateNewShipment(data)
            console.log(resp);
            if (resp?.status === 'ok')
                navigate('/shipments');
        }
    }

    useEffect(() => {
        getCountries();
    }, [])

    const getCountries = async () => {
        const resp = await GetCountries();
        console.log(resp);
        if (resp.length){
            var countriesForSelect = {
                '0': ''
            };
            resp.sort((a, b) => {
                return parseInt(a.sort_order) - parseInt(b.sort_order);
            })
            resp.map((item) => {
                delete item.sort_order;
                delete item.iso2;
                delete item.code;
                Object.assign(countriesForSelect, {
                    [item.id]: item.name
                });
            })
            setCountries(countriesForSelect);
        }
    }

    const search = async (newPhone) => {
        setPhone(newPhone);
        if (newPhone.length >= 5){
            const resp = await SearchClientByPhone(newPhone);
            
            if (!resp.length){
                setNewClient(true);
                setClients([]);
            }
            else{
                setClients(resp);
                setNewClient(false);
            }
        }
        else{
            setNewClient(false);
            setClients([]);
            setChosenClient(0);
            setPickupAddresses([]);
            setNewAddress(false);
            setChosenAddress(0);
            setShowMore(true);
            setPromoOne(false);
            setPackages([]);
            setDeliveryAddresses([]);
            setContactPersons([]);
            setFirstPackage(true);
        }
    }

    const clientPicker = async(id) => {
        setChosenClient(id);
        const resp = await GetAddresses(id, 'PICKUP');
        if (resp.status === 'Not Found'){
            setPickupAddresses([]);
            setNewAddress(true);
        }
        else if (resp.length){
            const addresses = resp;
            addresses.map((item, index) => {
                if (addresses.length - 1 === index)
                    item.hidden = false;
                else if (addresses.length - 2 === index)
                    item.hidden = false;
                else
                    item.hidden = true;
            })
            setPickupAddresses(resp);
            setNewAddress(false);
            setChosenAddress(0);
        }
        setShowMore(true);
        setPromoOne(false);
        setPackages([]);
        setDeliveryAddresses([]);
        setContactPersons([]);
        setFirstPackage(true);

        const opoResp = await GetPromoOpo(id);
        if (opoResp === true)
            setEnabledOpo(true);
        else
            setEnabledOpo(false);
    }
    const showAllAddresses = () => {
        if (pickupAddresses.length){
            pickupAddresses.map((item) => {
                item.hidden = false;
            });
            setPickupAddresses(
                [...pickupAddresses]
            );
            setShowMore(false);
        }     
    }

    const creatingNewAddress = async(type) => {
        if (type === 'PICKUP'){
            const resp = await GetAddresses(chosenClient, 'PICKUP');
            if (resp.status === 'Not Found'){
                setPickupAddresses([]);
                setNewAddress(true);
            }
            else if (resp.length){
                const addresses = resp;
                addresses.map((item, index) => {
                    if (addresses.length - 1 === index)
                        item.hidden = false;
                    else if (addresses.length - 2 === index)
                        item.hidden = false;
                    else
                        item.hidden = true;
                })
                setPickupAddresses(resp);
                setNewAddress(false);
                setChosenAddress(0);
                setShowMore(true);
            }
        }
        else{
            const resp2 = await GetAddresses(chosenClient, 'DELIVERY');
            if (resp2.status === 'Not Found'){}
            else if (resp2.length)
                setDeliveryAddresses(resp2);
        }
    }

    const creatingContactPerson = async() => {
        const respCP = await GetContactPersons(chosenClient);
        if (respCP.status === 'Not Found'){}
        else if (respCP.length)
            setContactPersons(respCP);
    }

    const addNewPackage = async () => {
        if (firstPackage === true){
            setFirstPackage(false);
            const resp = await GetAddresses(chosenClient, 'DELIVERY');
            if (resp.status === 'Not Found'){}
            else if (resp.length)
                setDeliveryAddresses(resp);

            const respCP = await GetContactPersons(chosenClient);
            if (respCP.status === 'Not Found'){}
            else if (respCP.length)
                setContactPersons(respCP);
        }

        const newPackage = {
            deliveryAddress: '',
            contactPerson: '',
            empty: false,
            gift: false,
            packageType: 'BIG',
            content: '',
            contentPrice: '',
            showAddresses: false,
            showPersons: false,
            chosenCountry: 0,
        }

        setPackages([...packages, newPackage]);
    }

    const removePackage = (index) => {
        packages.splice(index, 1);
        setPackages([...packages]);
    }
    const deliveryAddressChanger = (item, id) => {
        item.deliveryAddress = id;
        setPackages([...packages]);
    }
    const contactPersonChanger = (item, id) => {
        item.contactPerson = id;
        setPackages([...packages]);
    }
    const packageTypeChanger = (item, type) => {
        item.packageType = type;
        setPackages([...packages]);
    }
    const emptyChanger = (item, checked) => {
        item.empty = checked;
        setPackages([...packages]);
    }
    const giftChanger = (item, checked) => {
        item.gift = checked;
        setPackages([...packages]);
    }
    const showAddressesChanger = (item) => {
        item.showAddresses = true;
        setPackages([...packages]);
    }
    const showPersonsChanger = (item) => {
        item.showPersons = true;
        setPackages([...packages]);
    }
    const chosenCountryChanger = (item, id) => {
        item.chosenCountry = id;
        setPackages([...packages]);
    }
    const addressEdit = async(item) => {
        setEditAddress(item);
        setAddressType('PICKUP');
        setCreateAddress(true);
    }
    const personEdit = async(item) => {
        setEditPerson(item)
        setCreateContactPerson(true)
    }
    return (
        <div className='default-template'>
            <div className="dt-title">Create Shipment</div>
            <div className="dt-body">
                <form onSubmit={createShipment}>
                    <div className='wh-container'>
                        <div>
                            <InputWithLabel
                                value={phone}
                                onChange={search}
                                label='Search client'
                                placeholder='Enter 5 digits at least...'
                            />
                            {newClient === true &&
                                <Button onClick={() => setCreateClient(true)} className='client-card new-client'>
                                    <FaPlus size={28} color='#3c8dbc' />
                                </Button>
                            }
                            <div className='client-card-container'>
                                {clients.length ?
                                    clients.map((item, index) => {
                                        return(
                                            <InputRadioBox 
                                                name='clients' className={`client-card ${item.id === chosenClient ? 'active' : ''}`} key={index}
                                                inputClassName='hidden'
                                                value={item.id}
                                                onChange={clientPicker}
                                            >
                                                {item.phone_number} <br />
                                                {item.contact_name} <br />
                                                <div>
                                                    <FaIdCard className='mr-5' size={20} color={item.verified === '1' ? 'green' : 'red'} />
                                                    <FaShieldAlt className='mr-5' size={20} color={item.reliable === '1' ? '#ccc' : 'red'} />
                                                    <FaFileSignature size={20} color={item.attorney ? (item.attorney.status === '1' ? '#f1c40f' : 'green') : 'red'} />
                                                </div>
                                                {
                                                    item.passport_id &&
                                                    <>
                                                        Passport id:
                                                        {item.passport_id}
                                                    </>
                                                }
                                            </InputRadioBox>
                                        )
                                    })
                                    :
                                    <></>
                                }
                            </div>
                            <div className='pickup-addresses'>
                                <div className={'title ' + (chosenClient === 0 ? 'hidden' : '')}>
                                    Pickup Address:
                                </div>
                                <div className='pickup-addresses-card-container'>
                                    {
                                        pickupAddresses.length ?
                                        <>
                                            {
                                                pickupAddresses.map((item, index) => {
                                                    if (item.hidden !== true)
                                                    return(
                                                        <InputRadioBox 
                                                            key={index}
                                                            name='pickupAddress' 
                                                            className={`pickup-address-card${item.id === parseInt(chosenAddress) ? ' active' : ''}${item.hidden === true ? ' hidden' : ''}`}
                                                            inputClassName='hidden'
                                                            value={item.id}
                                                            onChange={() => {setChosenAddress(item.id)}}
                                                        >
                                                            {item.country && <>{item.country} <br /></>}
                                                            {item.region && <>{item.region} <br /></>}
                                                            {item.city && <>{item.city} <br /></>}
                                                            {item.postal_code && <>{item.postal_code} <br /></>}
                                                            {item.street && item.street}
                                                            {item.house && <>, H.: {item.house}</>}
                                                            {item.apartment && <>, Ap.: {item.apartment}</>}
                                                            <div onClick={() => addressEdit(item)} className='edit-pencil'>
                                                                <Button className='btn-icon bg-orange'>
                                                                    <FaPencilAlt color='white'/>
                                                                </Button>
                                                            </div>
                                                        </InputRadioBox>
                                                    )
                                                })
                                            }
                                            {
                                                pickupAddresses.length >= 3 ?
                                                <Button onClick={showAllAddresses} className={'pickup-address-card new-address' + (showMore === true ? '' : ' hidden')}>
                                                    Show more
                                                </Button>
                                                :
                                                <></>
                                            }
                                            
                                            <Button onClick={() => {
                                                setAddressType('PICKUP');
                                                setEditAddress({});
                                                setCreateAddress(true);
                                            }} className='pickup-address-card new-address'>
                                                <FaPlus size={28} color='#3c8dbc' />
                                            </Button>
                                        </>
                                        :
                                        newAddress === true &&
                                        <>
                                                <div className='w-100'>Not found</div>
                                                <Button onClick={() => {
                                                    setAddressType('PICKUP');
                                                    setCreateAddress(true);
                                                }} className='pickup-address-card new-address'>
                                                    <FaPlus size={28} color='#3c8dbc' />
                                                </Button>
                                        </>
                                    }
                                </div>
                            </div>
                            {/* <li><?= $address->country ?></li>
                            <li><?= $address->region ?></li>
                            <li><?= $address->city ?></li>
                            <li><?= $address->postal_code ?></li>
                            <?php if(!empty($address->street)): ?>
                            <li><?= $address->street ?>, H.: <?= $address->house ?>, Ap.: <?= $address->apartment ?></li>
                            <?php endif; ?> */}
                        </div>
                        <div>
                            {
                                chosenClient > 0 ?
                                <>
                                    <InputLabelAndChildren value={format(date, 'yyyy-MM-dd')} onChange={setDate} label='Pickup date' disabled={true}>
                                        <DatePicker
                                            selected={date}
                                            onChange={(date) => setDate(date)}
                                            customInput={<CustomButtonDate/>}
                                            closeOnScroll={true}
                                            dateFormat="MM/dd/yyyy h:mm aa"
                                            locale='enGb'
                                        />
                                    </InputLabelAndChildren>
                                    <div className='wh-container mb-0'>
                                        <div>
                                            <InputLabelAndChildren value={timeFrom !== '' ? format(timeFrom, 'HH:mm') : ''} onChange={setTimeFrom} label='Pickup time from' disabled={true}>
                                                <DatePicker
                                                    onChange={(time) => setTimeFrom(time)}
                                                    customInput={<CustomButtonTime/>}
                                                    showTimeSelect
                                                    showTimeSelectOnly
                                                    timeIntervals={15}
                                                    timeCaption="Time"
                                                    dateFormat="h:mm aa"
                                                    timeFormat="HH:mm"
                                                    closeOnScroll={true}
                                                />
                                            </InputLabelAndChildren>
                                        </div>
                                        <div>
                                            <InputLabelAndChildren value={timeTo !== '' ? format(timeTo, 'HH:mm') : ''} onChange={setTimeTo} label='Pickup time to' disabled={true}>
                                                <DatePicker
                                                    selected={timeTo}
                                                    onChange={(time) => setTimeTo(time)}
                                                    customInput={<CustomButtonTime/>}
                                                    showTimeSelect
                                                    showTimeSelectOnly
                                                    timeIntervals={15}
                                                    timeCaption="Time"
                                                    dateFormat="h:mm aa"
                                                    timeFormat="HH:mm"
                                                    closeOnScroll={true}
                                                />
                                            </InputLabelAndChildren>
                                        </div>
                                    </div>
                                    <div className='input-container'>
                                        <div className="title">Description</div>
                                        <textarea value={description} onChange={(e) => setDescription(e.target.value)}></textarea>
                                    </div>
                                    
                                </>
                                :
                                <></>
                            }
                            
                        </div>
                    </div>
                    {
                        chosenClient > 0 ?
                        <div className='wh-container'>
                            <div className='package-title'>
                                <div>
                                    Packages
                                </div>
                            </div>
                            <div className='promos'>
                                <div className='title'>Promo</div>
                                <InputRadioBox
                                    className={`promo-card ${promoOne === true ? 'active' : '' }`} type='checkbox' value='1'
                                    name='one_plus_one' inputClassName='hidden'
                                    onChange={setPromoOne}
                                    checked={promoOne}
                                    disabled={!isEnabledOpo}
                                >
                                    1+1
                                </InputRadioBox>
                            </div>
                        </div>
                        :
                        <></>
                    }
                    {
                        packages.length ?
                            <div className='packages'>
                                {packages.map((packageItem, packageIndex) => {
                                    return(
                                        <div key={packageIndex} className="package">
                                            <div className='header'>
                                                <div className='title'>Package {packageIndex + 1}</div>
                                            </div>
                                            <div className='body'>
                                                <div>
                                                    <div>Delivery Address</div>
                                                    <div className='pickup-addresses-card-container'>
                                                        <InputRadioBox 
                                                            name={'deliveryAddress' + packageIndex} 
                                                            className={`pickup-address-card jc-sb ${-1 === packageItem.deliveryAddress ? 'active' : ''}`}
                                                            inputClassName='hidden'
                                                            value={-1}
                                                            onChange={() => {deliveryAddressChanger(packageItem, -1)}}
                                                        >
                                                            I don't know address
                                                            <Select
                                                                data={Object.entries(countries)}
                                                                label='Delivery Country'
                                                                value={packageItem.chosenCountry}
                                                                placeholderFirst={true}
                                                                className='select-container c-pointer'
                                                                onChange={(country) => chosenCountryChanger(packageItem, country)}
                                                                onClickContainer={() => deliveryAddressChanger(packageItem, -1)}
                                                            />
                                                        </InputRadioBox>
                                                        {
                                                            deliveryAddresses.length ?
                                                            <>
                                                                {
                                                                    deliveryAddresses.map((item, index) => {
                                                                        if (deliveryAddresses.length - index <= 1 || packageItem.showAddresses === true)
                                                                            return(
                                                                                <InputRadioBox 
                                                                                    key={index}
                                                                                    name={'deliveryAddress' + packageIndex} 
                                                                                    className={`pickup-address-card ${item.id === packageItem.deliveryAddress ? 'active' : ''} ${packageItem.showAddresses === false ? (deliveryAddresses.length - index <= 1 ? '' : 'hidden') : ''}`}
                                                                                    inputClassName='hidden'
                                                                                    value={item.id}
                                                                                    onChange={() => {deliveryAddressChanger(packageItem, item.id)}}
                                                                                >
                                                                                    {item.country && <>{item.country} <br /></>}
                                                                                    {item.region && <>{item.region} <br /></>}
                                                                                    {item.city && <>{item.city} <br /></>}
                                                                                    {item.postal_code && <>{item.postal_code} <br /></>}
                                                                                    {item.street && item.street}
                                                                                    {item.house && <>, H.: {item.house}</>}
                                                                                    {item.apartment && <>, Ap.: {item.apartment}</>}
                                                                                    <div onClick={() => addressEdit(item)} className='edit-pencil'>
                                                                                        <Button className='btn-icon bg-orange'>
                                                                                            <FaPencilAlt color='white'/>
                                                                                        </Button>
                                                                                    </div>
                                                                                </InputRadioBox>
                                                                            )
                                                                    })
                                                                }
                                                                {
                                                                    deliveryAddresses.length >= 3 ?
                                                                    <Button onClick={() => {showAddressesChanger(packageItem)}} className={'pickup-address-card new-address' + (packageItem.showAddresses === false ? '' : ' hidden')}>
                                                                        Show more
                                                                    </Button>
                                                                    :
                                                                    <></>
                                                                }
                                                                
                                                                <Button onClick={() => {
                                                                    setAddressType('DELIVERY');
                                                                    setCreateAddress(true);
                                                                }} className='pickup-address-card new-address'>
                                                                    <FaPlus size={28} color='#3c8dbc' />
                                                                </Button>
                                                            </>
                                                            :
                                                            <>
                                                                <div className='w-100'>Not found</div>
                                                                <Button onClick={() => {
                                                                    setAddressType('DELIVERY');
                                                                    setCreateAddress(true);
                                                                }} className='pickup-address-card new-address'>
                                                                    <FaPlus size={28} color='#3c8dbc' />
                                                                </Button>
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                                <div>
                                                    <div>Contact Person</div>
                                                    <div className='pickup-addresses-card-container'>
                                                        {
                                                            contactPersons.length ?
                                                            <>
                                                                {
                                                                    contactPersons.map((item, index) => {
                                                                        if (contactPersons.length - index <= 2 || packageItem.showPersons === true)
                                                                            return(
                                                                                <InputRadioBox 
                                                                                    key={index}
                                                                                    name={'contactPerson' + packageIndex} 
                                                                                    className={`pickup-address-card ${item.id === packageItem.contactPerson ? 'active' : ''} ${packageItem.showPersons === false ? (contactPersons.length - index <= 2 ? '' : 'hidden') : ''}`}
                                                                                    inputClassName='hidden'
                                                                                    value={item.id}
                                                                                    onChange={() => {contactPersonChanger(packageItem, item.id)}}
                                                                                >
                                                                                    {item.phone_number && <>{item.phone_number} <br /></>}
                                                                                    {item.contact_name && <>{item.contact_name} <br /></>}
                                                                                    <div onClick={() => personEdit(item)} className='edit-pencil'>
                                                                                        <Button className='btn-icon bg-orange'>
                                                                                            <FaPencilAlt color='white'/>
                                                                                        </Button>
                                                                                    </div>
                                                                                </InputRadioBox>
                                                                            )
                                                                    })
                                                                }
                                                                {
                                                                    contactPersons.length >= 3 ?
                                                                    <Button onClick={() => {showPersonsChanger(packageItem)}} className={'pickup-address-card new-address' + (packageItem.showPersons === false ? '' : ' hidden')}>
                                                                        Show more
                                                                    </Button>
                                                                    :
                                                                    <></>
                                                                }
                                                                
                                                                <Button onClick={() => {setCreateContactPerson(true)}} className='pickup-address-card new-address'>
                                                                    <FaPlus size={28} color='#3c8dbc' />
                                                                </Button>
                                                            </>
                                                            :
                                                            <>
                                                                <div className='w-100'>Not found</div>
                                                                <Button onClick={() => {setCreateContactPerson(true)}} className='pickup-address-card new-address'>
                                                                    <FaPlus size={28} color='#3c8dbc' />
                                                                </Button>
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                                <div>
                                                    <Select
                                                        data={Object.entries({
                                                            'BIG': 'Big',
                                                            'SMALL': 'Small',
                                                            'NONSTANDARD': 'Non-standard',
                                                        })}
                                                        label='Package Type'
                                                        value={packageItem.packageType}
                                                        placeholderFirst={true}
                                                        onChange={(type) => packageTypeChanger(packageItem, type)}
                                                    />
                                                    <InputWithLabel
                                                        value={packageItem.contentPrice}
                                                        label='Content price'
                                                    />
                                                    <div className='wh-container mt-15'>
                                                        <div>
                                                            <InputRadioBox
                                                                inputClassName='hidden'
                                                                className={'default-card w-100' + (packageItem.empty === true ? ' active' : '')}
                                                                type='checkbox'
                                                                value={packageItem.empty}
                                                                onChange={(checked) => emptyChanger(packageItem, checked)}
                                                            >
                                                                Empty
                                                            </InputRadioBox>
                                                        </div>
                                                        <div>
                                                            <InputRadioBox
                                                                inputClassName='hidden'
                                                                className={'default-card w-100' + (packageItem.gift=== true ? ' active' : '')}
                                                                type='checkbox'
                                                                value={packageItem.gift}
                                                                onChange={(checked) => giftChanger(packageItem, checked)}
                                                            >
                                                                Gift
                                                            </InputRadioBox>
                                                        </div>
                                                    </div>
                                                    <Button onClick={() => setContent(true)} className='btn w-100 h-34'>
                                                        Content
                                                    </Button>
                                                    <Button onClick={() => removePackage(packageIndex)} className='btn w-100 h-34 bg-red2 mt-15'>
                                                        Remove
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                                
                            </div>
                        :
                        <></>
                    }

                    {
                        chosenClient > 0 ?
                        <div className='mv-20'>
                            <Button className='btn wft' onClick={addNewPackage}>
                                Add package
                            </Button>
                        </div>
                        :
                        <></>
                    }
                    
                    <Button type='submit' className='btn bg-green'>
                        Create
                    </Button>
                </form>
            </div>
            <CreateClientModal visible={createClient} setVisible={setCreateClient} phone={phone} search={search}/>
            <CreateAddressModal address={editAddress} callback={creatingNewAddress} visible={createAddress} setVisible={setCreateAddress} addressType={addressType} clientId={chosenClient}/>
            <CreateContactPersonModal setPerson={setEditPerson} person={editPerson} visible={createContactPerson} setVisible={setCreateContactPerson} clientId={chosenClient} callback={creatingContactPerson}/>
            <Content visible={content} setVisible={setContent} content={packages[chosenPackage]} />
        </div>
    );
}