import React, { useContext } from 'react';
import { UserContext } from '../../context';
import { IoMdArrowDown } from "react-icons/io";


export const SidebarAccordion = ({className, role, children, text, callback, opened, icon}) => {
    const {user} = useContext(UserContext);

    if (role)
        if (user.role !== role)
            return null;

    return (
        <li className={'dropdown-sb' + (opened === true ? ' opened' : '') + (className ? ' ' + className : '')}>
            <div onClick={() => callback('analytics', !opened)} className='dropdown-label'>
                <div>{icon ? icon : ''}{text ? text : ''}</div>
                <IoMdArrowDown color='white' size={16}/>
            </div>
            <ul>
                {children}
            </ul>
        </li>
    );
}