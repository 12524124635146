import React, { useState, useEffect, useRef } from 'react';
import {FaBars, FaCircle, FaDigitalTachograph, FaPhone, FaPhoneAlt, FaUser} from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';
import { Button } from '../ui/Button';
import { Select } from '../ui/Select';
import { AssignDriver } from '../../api/Shipment';
import { GetDrivers } from '../../api/User';
import { useNavigate } from "react-router-dom";
import { CreateAddress, CreatingClient } from '../../api/Client';
import { InputWithLabel } from '../ui/InputWithLabel';
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { PlacesAutocomplete } from '../ui/PlacesAutocomplete';


export const CreateAddressModal = ({visible, setVisible, addressType, clientId, callback, address}) => {

    const [id, setId] = useState(0);
    const [postalCode, setPostalCode] = useState('');
    const [countryCode, setCountryCode] = useState('');
    const [country, setCountry] = useState('');
    const [city, setCity] = useState('');
    const [street, setStreet] = useState('');
    const [house, setHouse] = useState('');
    const [apartment, setApartment] = useState('');
    const [region, setRegion] = useState('');
    const [formattedAddress, setFormattedAddress] = useState('');
    const [coordinates, setCoordinates] = useState({});
    const [type, setType] = useState(addressType);

    const ref = useRef(null);
    const [map, setMap] = useState();
    const marker = useRef()

    useEffect(() => {
        console.log(formattedAddress)
    }, [formattedAddress])

    useEffect(() => {
        if (visible === true){
            if (ref.current && !map) {
                let center = { lat: 31.757, lng: 35.212 }
                console.log(address)
                if (Object.keys(address).length > 0 && address.coordinates && address.coordinates !== ''){
                    center = {lat: parseFloat(JSON.parse(address?.coordinates)?.lat), lng: parseFloat(JSON.parse(address?.coordinates)?.lng)}
                }
                const zoom = 10
                const map1 = new window.google.maps.Map(ref.current, {
                    center,
                    zoom
                })
                setMap(map1)
                var geocoder = new window.google.maps.Geocoder();
                console.log(address)
                if (Object.keys(address).length > 0){
                    if (address.coordinates && address.coordinates !== '')
                        geocoder.geocode( { 'location': {lat: parseFloat(JSON.parse(address?.coordinates).lat), lng: parseFloat(JSON.parse(address?.coordinates).lng)}}, function(results, status) {
                            if (status == 'OK') {
                                // map.setCenter(results[0].geometry.location);
                                console.log(map)
                                marker.current = new window.google.maps.Marker({
                                    map: map1,
                                    position: results[0].geometry.location
                                })
                                getAddress(results[0])
                            
                            }
                            else {
                                alert('Geocode was not successful for the following reason: ' + status);
                            }
                        })
                }
            }
        }
        else{
            if (map){
                window.google.maps.event.clearListeners(map, 'click')
                setMap()
                marker.current = undefined
                setCoordinates({})
            }  
        }
    }, [visible]);
    useEffect(() => {
        if (map)
            map.addListener('click', (event) => {
                //console.log(event.latLng.lat())
                if (marker.current)
                    marker.current.setMap(null)
                /* var request = {
                    location: event.latLng,
                    radius: '50',
                    types: ['address']
                };
                
                var service = new window.google.maps.places.PlacesService(map); */
                var geocoder = new window.google.maps.Geocoder();
                geocoder.geocode( { 'location': event.latLng}, function(results, status) {
                    if (status == 'OK') {
                     // map.setCenter(results[0].geometry.location);
                      marker.current = new window.google.maps.Marker({
                          map: map,
                          position: results[0].geometry.location
                      })
                      getAddress(results[0])
                      
                    } else {
                      alert('Geocode was not successful for the following reason: ' + status);
                    }
                })
                /* const x = service.nearbySearch(request, (result, status) => {
                    console.log(result, status)
                }); */
                /* marker.current = new window.google.maps.Marker({
                    position: event.latLng,
                    map,
                }) */
                console.log(marker.current)
                /* getAddress({
                    geometry: {
                        location: {
                            lat(){return event.latLng.lat()},
                            lng(){return event.latLng.lng()},
                        }
                    }
                }) */
                
            })
    }, [map])

    useEffect(() => {
        setType(addressType);
    }, [addressType])
    useEffect(() => {
        if (address.id){
            setId(address.id)
            setPostalCode(address.postal_code);
            setCountryCode(address.country_code);
            setCountry(address.country);
            setCity(address.city);
            setStreet(address.street);
            setHouse(address.house);
            setApartment(address.apartment);
            setRegion(address.region);
            setFormattedAddress(address.formatted_address);
            setCoordinates(address.coordinates);
        }
        else{
            setPostalCode('');
            setCountryCode('');
            setCountry('');
            setCity('');
            setStreet('');
            setHouse('');
            setApartment('');
            setRegion('');
            setFormattedAddress('');
            setCoordinates('');
        }
    }, [visible])

    const createAddress = async(e) => {
        e.preventDefault();
        if (country !== ''){
            var data = {
                [id !== 0 ? 'id' : '']: id,
                postalCode: postalCode,
                countryCode: countryCode,
                country: country,
                city: city,
                street: street,
                house: house,
                apartment: apartment,
                region: region,
                formattedAddress: formattedAddress,
                coordinates: coordinates,
                type: type,
                clientId: clientId,
            }
    
            const resp = await CreateAddress(data);
            console.log(resp);
    
            if (resp.status === 'ok'){
                callback(type);
                setVisible(false);
            } 
        }
    }
    
    const getAddress = (data) => {
        var componentForm = {
            street_number: 'short_name',
            route: 'long_name',
            locality: 'long_name',
            administrative_area_level_1: 'short_name',
            country: 'long_name',
            postal_code: 'short_name'
        };

        console.log(data)
        if (!data.formatted_address)
            setFormattedAddress(data.formatted_address);
        setCoordinates(
            JSON.stringify({"lat":data.geometry.location.lat().toString(), "lng":data.geometry.location.lng().toString()})
        )

        for (var i = 0; i < data.address_components.length; i++) {
            var addressType = data.address_components[i].types[0];
            //console.log(addressType);
            if (componentForm[addressType]){
                switch (addressType){
                    case 'postal_code':
                        setPostalCode(data.address_components[i][componentForm[addressType]]);
                        break;
                    case 'country':
                        setCountry(data.address_components[i][componentForm[addressType]]);
                        setCountryCode(data.address_components[i]['short_name']);
                        break;
                    case 'locality':
                        setCity(data.address_components[i][componentForm[addressType]]);
                        break;
                    case 'administrative_area_level_1':
                        setRegion(data.address_components[i][componentForm[addressType]]);
                        break;
                    case 'route':
                        setStreet(data.address_components[i][componentForm[addressType]]);
                        break;
                    case 'street_number':
                        setHouse(data.address_components[i][componentForm[addressType]]);
                        break;
                }
            }
        }
    }

    const renderMap = () => {
        return(
            <div ref={ref} id='gMap'></div>
        )
    }

    if (visible == false)
        return null;

    return (
        <div onClick={() => setVisible(false)} className='modal scrollable'>
            <div onClick={(e) => {e.stopPropagation()}} className='modal-container-create-address'>
                <div className='driver-picker'>
                    <form onSubmit={createAddress}>
                        
                        <div className='title'>
                            {address.id ? 'Edit address' : 'Create New Address'}
                        </div>
                        <div className='wh-container'>
                            <div>
                                <Select
                                    data={Object.entries({
                                        'DELIVERY'  : 'Delivery',
                                        'PICKUP': 'Pickup',
                                    })}
                                    label='Type'
                                    value={type}
                                    onChange={setType}
                                />
                                <PlacesAutocomplete
                                    getAddress={getAddress}
                                />
                                <InputWithLabel
                                    value={postalCode}
                                    onChange={setPostalCode}
                                    label='Postal Code'
                                />
                                <InputWithLabel
                                    value={countryCode}
                                    onChange={setCountryCode}
                                    label='Country Code'
                                />
                                <InputWithLabel
                                    value={country}
                                    onChange={setCountry}
                                    label='Country'
                                />
                                <InputWithLabel
                                    value={city}
                                    onChange={setCity}
                                    label='City'
                                />
                                <InputWithLabel
                                    value={street}
                                    onChange={setStreet}
                                    label='Street'
                                />
                                <InputWithLabel
                                    value={house}
                                    onChange={setHouse}
                                    label='House'
                                />
                                <InputWithLabel
                                    value={apartment}
                                    onChange={setApartment}
                                    label='Apartment'
                                />
                            </div>
                            <div ref={ref} id='gMap'></div>
                        </div>
                        
                        <Button type='submit' className='btn bg-green'>
                            {address.id ? 'Update' : 'Create'}
                        </Button>
                    </form>
                </div>
            </div>
        </div>
    );
}