import React, { forwardRef, useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button } from '../../components/ui/Button';
import { Pagination } from '../../components/ui/Pagination';
import { LoadingContext, UserContext } from '../../context';
import {FaIdCard, FaShieldAlt, FaMapMarkerAlt, FaEye, FaPencilAlt, FaTrash, FaCalendarAlt} from 'react-icons/fa';
import { Input } from '../../components/ui/Input';
import { InputWithLabel } from '../../components/ui/InputWithLabel';
import { GACreatedShipments, GAEmptyBoxes, GASentPoaHtm } from '../../api/Analitics';
import DatePicker from "react-datepicker";
import { InputLabelAndChildren } from '../../components/ui/InputLabelAndChildren';
import { compareAsc, format } from 'date-fns'

export const EmptyBoxes = () => {
    const {setLoading} = useContext(LoadingContext);
    const {user} = useContext(UserContext);
    const [data, setData] = useState(null);
    const [id, setId] = useState('');
    const [date, setDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());
    let location = useLocation();
    const navigate = useNavigate();

    const CustomButtonDate = forwardRef(({ onClick }, ref) => (
        <button type='button' className='btn-calendar' onClick={onClick} ref={ref}>
            <FaCalendarAlt color='#555'/>
        </button>
    ))
    const CustomButtonDateTo = forwardRef(({ onClick }, ref) => (
        <button type='button' className='btn-calendar' onClick={onClick} ref={ref}>
            <FaCalendarAlt color='#555'/>
        </button>
    ))

    useEffect(() => {
        console.log('--------------------')
        console.log(date)
        console.log(toDate)
    }, [date, toDate])

    useEffect(() => {
        const getData = async() => {
            setLoading(true);
            if (user){
                const resp = await GAEmptyBoxes(location.search);
                console.log(resp);
                setData(resp);
                console.log(resp.ships[0])
                setLoading(false);
            }  
        }
        getData();
    }, [user, location])

    const dateChanger = (newDate) => {
        setDate(newDate);
        paramsChanger('date', newDate);
    }

    const dateChangerTo = (newDate) => {
        setToDate(newDate);
        paramsChanger('to-date', newDate);
    }

    const paramsChanger = (param, newDate) => {
        var params = new URLSearchParams(location.search);
        if (params){
            params.delete('page');
            switch (param){
                case 'date':
                    params.set(param, format(newDate, 'yyyy-MM-dd'));
                    break;
                case 'to-date':
                    params.set(param, format(newDate, 'yyyy-MM-dd'));
                    break;
            }
            navigate('/analitics/empty-boxes?' + params);
        }
    }

    const findByParam = (key, param) => {
        if (key.length){
            if (key == 'Enter'){
                paramsChanger(param);
            }
        }
        else{
            paramsChanger(param);
        }
    }

    return (
        <div className='default-template'>
            <div className="dt-title">Empty and filled packages</div>
            <div className="dt-body">
                {/* <div className="count-of-elements">
                    Found <span>{data ? data.count : '???'}</span> items.
                </div> */}
                <InputLabelAndChildren value={format(date, 'yyyy-MM-dd')} onChange={setDate} label='Date from' disabled={true}>
                    <DatePicker
                        selected={date}
                        onChange={(date) => dateChanger(date)}
                        customInput={<CustomButtonDate/>}
                        closeOnScroll={true}
                        dateFormat="MM/dd/yyyy h:mm aa"
                    />
                </InputLabelAndChildren>
                <InputLabelAndChildren value={format(toDate, 'yyyy-MM-dd')} onChange={setToDate} label='Date to' disabled={true}>
                    <DatePicker
                        selected={toDate}
                        onChange={(toDate) => dateChangerTo(toDate)}
                        customInput={<CustomButtonDateTo/>}
                        closeOnScroll={true}
                        dateFormat="MM/dd/yyyy h:mm aa"
                    />
                </InputLabelAndChildren>
                <div className='table-default'>
                    <table className='table-clients'>
                        <thead>
                            <tr>
                                <th>Empty</th>
                                <th>Full</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{data?.ships && data.ships[0].empty}</td>
                                <td>{data?.ships && data.ships[0].full}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <Pagination totalCount={data?.opers?.length > 20 ? 21 : 1} refresher={location}/>
            </div>
        </div>
    );
}