import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
    getDetails
} from "use-places-autocomplete";
  
export const PlacesAutocomplete = ({getAddress}) => {
    const {
      ready,
      value,
      suggestions: { status, data },
      setValue,
      clearSuggestions,
    } = usePlacesAutocomplete({
      requestOptions: {
        /* Define search scope here */
      },
      debounce: 300,
    });
  
    const handleInput = (e) => {
      // Update the keyword of the input element
      setValue(e.target.value);
    };
  
    const handleSelect =
      ({ description }, id) =>
      () => {
        // When user selects a place, we can replace the keyword without request data from API
        // by setting the second parameter to "false"
        setValue(description, false);
        clearSuggestions();

        //console.log(description)
        // Get latitude and longitude via utility functions
        getGeocode({ address: description }).then((results) => {
          const { lat2, lng2 } = getLatLng(results[0]);
          /* console.log("📍 Coordinates: ", { lat, lng });
          console.log(description) */
        });
        const parameter = {
          placeId: id,
        }
        //console.log(id)
        getDetails(parameter)
          .then((details) => {
            getAddress(details);
            //console.log("Details: ", details);
          })
          .catch((error) => {
            console.log("Error: ", error);
          });
      };
  
    const renderSuggestions = () =>
      data.map((suggestion) => {
        const {
          place_id,
          structured_formatting: { main_text, secondary_text },
        } = suggestion;
  
        return (
          <li key={place_id} onClick={handleSelect(suggestion, place_id)}>
            <strong>{main_text}</strong> <small>{secondary_text}</small>
          </li>
        );
      });
      //onBlur={() => {clearSuggestions()}}
    return (
        <div className="input-container autocomplete">
          <label>Enter Address</label>
          <input
            value={value}
            onChange={handleInput}
            disabled={!ready}
          />
          {/* We can use the "status" to decide whether we should display the dropdown or not */}
          {status === "OK" && <ul className="autocomplete-vars">{renderSuggestions()}</ul>}
        </div>
    );
};