import React, { useState, useRef, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { Header } from './components/Header';
import { IncomeCall } from './components/IncomeCall';
import { OutgoingCall } from './components/OutgoingCall';
import { Sidebar } from './components/Sidebar';
import { UserAgent, Registerer, Inviter } from 'sip.js'
import { Loading } from './components/Loading';
import { DoCallContext, LoadingContext, UserContext } from '../context';
import { GetMainAccountInfo, GetProfile } from '../api/User';
import { format } from 'date-fns'
import { Call } from '../api/Call';
import { GetClientByPhone } from '../api/Client';
import { SureModal } from '../components/modal/SureModal';
import { VIEWER } from '../const/const';
import { clearRegionNumber, findClient } from '../helper/helper';

export const Layout = ({children}) => {
    const [isSidebarOpen, setSidebarOpen] = useState(true);
    const [incomeCallVisible, setIncomeCallVisible] = useState(false);
    const [callVisible, setCallVisible] = useState(false);
    const [status, setStatus] = useState('Disconnected')
    const [callStatus, setCallStatus] = useState('Established')
    const [phone, setPhone] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [phoneUser, setPhoneUser] = useState('')
    //const [inviter, setInviter] = useState(null)
    const [time, setTime] = useState(false)
    const [timer, setTimer] = useState(0)
    const [mutePhone, setMutePhone] = useState(false)
    const [modalForward, setModalForward] = useState(false)
    const [modalAccept, setModalAccept] = useState(false)
    const [forwardNumber, setForwardNumber] = useState('')
    const [name, setName] = useState('')
    const [call, setCall] = useState(false)
    const [dateState, setDateState] = useState('')
    const [callTimeState, setCallTimeState] = useState('')
    const [internalCallStatusState, setInternalCallStatusState] = useState(0);
    const date = useRef('');
    const callTime = useRef('');
    const internalCallStatus = useRef(0); //flag 1 - outgoing, 2 - incoming, 3 - missed
    const clientPhone = useRef('')
    const duration = useRef(0);
    const [loading, setLoading] = useState(false);
    const userId = useRef(0);
    const userName = useRef('');
    const inviterNew = useRef(null);
    const [user, setUser] = useState(null);
    const navigate = useNavigate();
    const [doCall, setDoCall] = useState('');
    const [client, setClient] = useState({});
    const [incCalls, setIncCalls] = useState([])
    //Initialization variables
    const audioElement = useRef()
    const userAgentOptions = useRef({})
    const userAgent = useRef()
    const registerer = useRef()
    const incomeCalls = useRef([])
    const callChecker = useRef(null)
    const inviter = useRef(null)


    /* useEffect(() => {
      clientPhone.current = phoneUser;
      if (phoneUser !== ''){
        findClient();
      }
      else
        setClient({});

          
    }, [phoneUser]) */

    useEffect(() => {
      /* console.log('wwwwwwwwwwwwwwwwwwwwwwwww')
      console.log(incomeCalls.current.length) */
      console.log('eeeeeeeeeeeeeee')
      console.log(incomeCalls.current.length)
      if (incomeCalls.current.length > 0)
        addClientToIncomeCall(incomeCalls.current[incomeCalls.current.length - 1])
        /* clearInterval(callChecker.current)
        callChecker.current = setInterval(() => checkCalls(), 1000)
      }
      else{
        console.log('cleared mfakca')
        clearInterval(callChecker.current)
      }
      if (incomeCalls.current.length === 0){
        console.log('cleared mfakca')
        clearInterval(callChecker.current)
      } */
    }, [incomeCalls.current.length])

    /* useEffect(() => {
      console.log('rrrrrrrrrrrrrrrrrrrr')
      console.log(incCalls)
    }, [incCalls]) */

    const checkCalls = () => {
      console.log(incomeCalls)
      incomeCalls.current = incomeCalls.current.filter((a) => a.state !== 'Terminated')
    }

    const addClientToIncomeCall = async(inv) => {
      inv.client = await findClient(clearRegionNumber(inv?.remoteIdentity?.uri.normal.user))
      setIncCalls(incCalls+1)
    }

    useEffect(() => {
      if (phone === ''){
        setDoCall('');
      }
    }, [phone])

    useEffect(() => {
      duration.current = format(new Date(timer * 1000), 'mm:ss')
    }, [timer])

    useEffect(() => {
      date.current = dateState
    }, [dateState])

    useEffect(() => {
      internalCallStatus.current = internalCallStatusState
    }, [internalCallStatusState])

    useEffect(() => {
      callTime.current = callTimeState
    }, [callTimeState])

    useEffect(() => {
      if (doCall !== ''){
        setPhone(doCall);
        setCallVisible(true);
      }
    }, [doCall])

    useEffect(() => {
      checkAuth();
    }, [])

    /* useEffect(() => {
      console.log('------------')
      console.log(inviterNew.current)
      console.log('------------')
    }, [inviterNew.current]) */

    const checkAuth = async () => {
      if (localStorage.getItem('token')){
        const user = await GetMainAccountInfo();
        console.log(user)
        if (!user.error){
          setUser(user);
          setName(user.internal_number);
          userId.current = user.id;
          userName.current = user.internal_number;
          userName.pass = user.pbx_pass;
          console.log(userId.current)
          userAgentOptions.current = {
            logBuiltinEnabled: false,
            displayName: userName.current,
            authorizationPassword: userName.pass, //user.pbx_pass //Qweasd123!!
            authorizationUsername: '',
            media: {
              constraints: {
                video: false,
                audio: true
              }
            },
            transportOptions: {
              server: 'wss://pbx.familiaips.com:8089/ws',
              transport: 'wss'
            },
            delegate: {
              onInvite: invitation => {
                incomeCalls.current = [...incomeCalls.current, invitation]
                setIncCalls(invitation)
                console.log(incomeCalls)
                
                /* console.log(incomeCalls.current)
                console.log(inviterNew)
                console.log(inviterNew.current) */
                //if (inviter.current === null){
                  setLoading(false)
                  setIncomeCallVisible(true)
                  setModalAccept(true)
                /* console.log(invitation)
                setIncomeCalls([...incomeCalls, invitation])
                console.log(incomeCalls) */
                //console.log(incomeCalls.current.find((item) => item._id === inviter.current._id))
                  inviterNew.current = invitation
                  //setPhoneUser(clearRegionNumber(invitation.remoteIdentity.displayName));
                  console.log(incomeCalls.current[incomeCalls.current.length - 1])
                  invitation.stateChange.addListener(state => {
                    const inc = incomeCalls.current[incomeCalls.current.length - 1]
                    setCallStatus(state)
                    console.log('state: ' + state)
                    if (state === 'Establishing') {
                      /* setDateState(format(new Date(), 'yyyy-MM-dd'))
                      setCallTimeState(format(new Date(), 'HH:mm')) */
                      if (invitation === inviter.current){
                        console.log('wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww')
                        date.current = format(new Date(), 'yyyy-MM-dd')
                        callTime.current = format(new Date(), 'HH:mm')
                      }
                    }
                    if (state === 'Established') {
                      console.log('estabeeeeeee')
                      if (invitation === inviter.current){
                        const remoteStream = new MediaStream()
                        invitation.sessionDescriptionHandler.peerConnection.getReceivers().forEach(receiver => {
                          if (receiver.track) {
                            remoteStream.addTrack(receiver.track)
                          }
                        })
                        audioElement.current.srcObject = remoteStream
                        audioElement.current.play()
                        setIncCalls([...incCalls, inviter])
                        setLoading(false);
                        setCallVisible(true);
                        setTime(true)
                        setInternalCallStatusState(1);
                      }
                    }
                    if (state === 'Terminated') {
                      if (invitation === inviter.current){
                        audioElement.current.srcObject = null
                        audioElement.current.pause()
                        setTime(false)
                        setModalAccept(false)
                        setCall(false)
                        setIncomeCallVisible(false)
                        setCallVisible(false)
                        setMutePhone(false)
                        /* setDateState(format(new Date(), 'yyyy-MM-dd'))
                        setCallTimeState(format(new Date(), 'HH:mm')) */
                        date.current = format(new Date(), 'yyyy-MM-dd')
                        callTime.current = format(new Date(), 'HH:mm')
                        if (internalCallStatus.current === 1){
                          console.log(userId)
                          const resp = calling(2);
                          console.log(resp);
                        }
                        else{
                          console.log(userId)
                          const resp = calling(3);
                          console.log(resp);
                        }
                        inviter.current = null
                        setInternalCallStatusState(0);
                        setTimer(0);
                      }
                      else{
                        incomeCalls.current = incomeCalls.current.filter((a) => a.state !== 'Terminated')
                        setIncCalls(incomeCalls.current)
                      }
                    }
                  })
                }
              },
              onDisconnect: error => {
                setStatus('Disconnect')
                console.log('disconnected')
                console.log(error)
              },
              onConnect: () => {
                console.log('connected')
                console.log(userName.current)
                setStatus('Connected')
              },
            //},
            uri: UserAgent.makeURI('sip:' + userName.current + '@pbx.familiaips.com:5060'),
          }
          userAgent.current = new UserAgent(userAgentOptions.current);
          registerer.current = new Registerer(userAgent.current);
          userAgent.current.start().then(() => {
            registerer.current.register()
          })
          console.log(userAgentOptions)
        }
        else{
          console.log('cant find get user profile')
          /* localStorage.removeItem('token');
          navigate('/login', true); */
        }
      }
      else{
        console.log('cant find token')
        navigate('/login', true);
      }
    }

    
  
    //Function for call to user
    const makeCall = () => {
      userAgent.current.start().then(() => {
        setLoading(true);
        const target = UserAgent.makeURI(`sip:${phone}@pbx.familiaips.com:5060`)
        console.log(target)
        inviter.current = new Inviter(userAgent.current, target)
        inviter.current.client = addClientToIncomeCall(inviter.current)
        inviter.current.invite()
        setIncCalls([...incCalls, inviter])
        inviter.current.stateChange.addListener(state => {
          //setPhoneUser(clearRegionNumber(target.normal.user))
          setCallStatus(state)
          if (state === 'Establishing') {
            console.log(state)
            //inviterNew.current = inviter
            setCall(true)
            setDateState(format(new Date(), 'yyyy-MM-dd'))
            setCallTimeState(format(new Date(), 'HH:mm'))
          }
          if (state === 'Established') {
            setLoading(false);
            const remoteStream = new MediaStream()
            inviter.current.sessionDescriptionHandler.peerConnection.getReceivers().forEach(receiver => {
              if (receiver.track) {
                remoteStream.addTrack(receiver.track)
              }
            })
            audioElement.current.srcObject = remoteStream
            audioElement.current.play()
            setTime(true)
            setInternalCallStatusState(1);;
          }
          if (state === 'Terminated') {
            setLoading(false);
            audioElement.current.srcObject = null
            audioElement.current.pause()
            setCallStatus('')
            setPhoneUser('')
            setCall(false)
            setTime(false)
            setIncomeCallVisible(false)
            setCallVisible(false)
            setMutePhone(false)
            setDateState(format(new Date(), 'yyyy-MM-dd'))
            setCallTimeState(format(new Date(), 'HH:mm'))
            if (internalCallStatus.current === 1){
              console.log(userId)
              const resp = calling(1);
              console.log(resp);
            }
            /* else{
              const resp = calling(3);
              console.log(resp);
            } */
            setInternalCallStatusState(0);
            setTimer(0);
            inviter.current = null
          }
          console.log(state)
        })
        setPhone('')
      })
    }
    const acceptCall = (inv_id) => {
      /* console.log(incomeCalls)
      incomeCalls[0].accept() */
      //console.log(incomeCalls)
      inviter.current = incomeCalls.current[inv_id]
      inviter.current.accept()
      incomeCalls.current = incomeCalls.current.filter((a) => a.state === 'Initial')
      setIncCalls(incomeCalls.current)
      //inviter.accept();
      console.log('accept')
    }
    const rejectCall = (inv_id) => {
      incomeCalls.current[inv_id].reject()
      console.log('qqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq')
      console.log(incomeCalls.current.length)
      incomeCalls.current = incomeCalls.current.filter((a) => a.state !== 'Terminated')
      setIncCalls(incomeCalls.current)
      console.log(incomeCalls.current.length)
      
      console.log('reject')
    }
    const endCall = () => {
        inviter.current.bye();
        console.log('end')
    }
    const mute = () => {
        setMutePhone(!mutePhone);
        inviter.current.sessionDescriptionHandler.peerConnection.getLocalStreams().forEach(function (stream) {
          stream.getAudioTracks().forEach(function (track) {
              track.enabled = mutePhone
              //track.muted = true
          })
        })
        /* console.log(invi)
        inviter.current = invi.current */
        
        setIncCalls([...incCalls, inviter])
    }

    const calling = async (stat) => {
      console.log(userId)
      return await Call(userId.current, clientPhone.current, date.current, callTime.current, duration.current, stat);
    }

    useEffect(() => {
      /* console.log(time)
      var timer2;
      var newTime = 0;
      if (time === true){
        setTimer(0)
        timer2 = setTimeout(function run() {
            newTime += 1;
            setTimer(newTime)
            console.log(newTime);
            setTimeout(run, 1000);
        }, 1000);
      }
      else
        clearTimeout(timer2);
      console.log(timer); */
      var interval;
      if (time === true){
        interval = setInterval(() => tick(1), 1000);
      }
        
      return () => {
        clearInterval(interval);
      };

    }, [time])

    const tick = (num) => {
      setTimer(prevTime => prevTime + num);
    }

    if (user?.role === VIEWER)
      return(
        <div>
          Access denied <br />
          Please check: <a onClick={() => localStorage.removeItem('token')} href="https://crm.familiaips.com/dashboard/moldova-pack/containers">crm.familiaips.com</a>
        </div>
      )

    return (
      <UserContext.Provider value={{
        user,
        setUser
      }}>
        <LoadingContext.Provider value={{
          loading,
          setLoading
        }}>
          <DoCallContext.Provider value={{
            doCall,
            setDoCall,
          }}>
            {
              user ?
                <div className='main'>
                  <Header
                    visible={incomeCallVisible}
                    phoneNumber={phoneUser}
                    setVisible={setIncomeCallVisible}
                    openCall={setCallVisible}
                    acceptCall={acceptCall}
                    rejectCall={rejectCall}
                    setCall={setCall}
                    setCallVisible={setCallVisible}
                    setLoading={setLoading}
                    client={client}
                    incomeCalls={incomeCalls}
                  />
                  <div className='container'>
                      <Sidebar />
                      <div className='main-container'>
                          <main>
                              <Outlet />
                          </main>
                          <div className='footer'></div>
                      </div>
                  </div>
                  {/* <IncomeCall
                      phoneNumber={phoneUser}
                      acceptCall={acceptCall}
                      rejectCall={rejectCall}
                      visible={incomeCallVisible}
                      setVisible={setIncomeCallVisible}
                      setCall={setCall}
                      setCallVisible={setCallVisible}
                      setLoading={setLoading}
                      client={client}
                  /> */}
                  <OutgoingCall
                      makeCall={makeCall}
                      phoneNumber={phone}
                      setPhoneNumber={setPhone}
                      visible={callVisible}
                      setVisible={setCallVisible}
                      call={call}
                      endCall={endCall}
                      setCall={setCall}
                      mute={mute}
                      phone={phoneUser}
                      timer={timer}
                      client={client}
                      status={status}
                      inviter={inviter}
                  />
                  <Loading
                      setVisible={setLoading}
                      visible={loading}
                  />
                  <audio id='remoteAudio' controls ref={audioElement}>
                      <p>Your browser doesn't support HTML5 audio.</p>
                  </audio>
                </div>
                :
                <></>
            }
          </DoCallContext.Provider>
        </LoadingContext.Provider>
      </UserContext.Provider>
    );
}