import React from 'react';
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Layout } from './layout/Layout';
import { myroutes, Router, routes, Routes } from './routes/Router';
import { Home } from './pages/Home';
import { Caller } from './pages/call-center/Caller';
import { Login } from './pages/Login';
import { Profile } from './pages/user/Profile';
import { Clients } from './pages/client';
import { Client } from './pages/client/Client';
import { CreateClient } from './pages/client/CreateClient';
import { Shipments } from './pages/shipments';
import { CreateShipment } from './pages/shipments/CreateShipment';
import { Calls } from './pages/call-center/Calls';
import { UpdateShipment } from './pages/shipments/UpdateShipment';
import { CreatedShipments } from './pages/analitics/CreatedShipments';
import { UpdateClient } from './pages/client/Update';
import { ActionLog } from './pages/logs/ActionLog';
import { SentPoaHtm } from './pages/analitics/SentPoaHtm';
import { RepShipments } from './pages/report/shipments';
import { HowManyBoxes } from './pages/analitics/HowManyBoxes';
import { EmptyBoxes } from './pages/analitics/EmptyBoxes';
import { Wrapper } from '@googlemaps/react-wrapper';


const router = createBrowserRouter([
  {
      path: "/",
      element: <Layout/>,
      children: [
        {
          path: "home",
          element: <Home />,
        },
        {
          path: "caller",
          element: <Caller />,
        },
        {
          path: 'profile',
          element: <Profile />
        },
        {
          path: 'clients',
          element: <Clients />,
        },
        {
          path: 'clients/view',
          element: <Client />
        },
        {
          path: 'clients/create',
          element: <CreateClient />
        },
        {
          path: 'shipments',
          element: <Shipments />
        },
        {
          path: 'shipments/create',
          element: <CreateShipment />
        },
        {
          path: 'shipments/update',
          element: <UpdateShipment />
        },
        {
          path: 'call-log',
          element: <Calls />
        },
        {
          path: 'analitics/created-shipments',
          element: <CreatedShipments />
        },
        {
          path: 'analitics/sent-poa-htm',
          element: <SentPoaHtm />
        },
        {
          path: 'analitics/how-many-boxes',
          element: <HowManyBoxes />
        },
        {
          path: 'clients/update',
          element: <UpdateClient />
        },
        {
          path: 'action-log',
          element: <ActionLog />
        },
        {
          path: 'report/shipment',
          element: <RepShipments />
        },
        {
          path: 'analitics/empty-boxes',
          element: <EmptyBoxes />
        },
      ],
  },
  {
    path: 'login',
    element: <Login />
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
