import React, { useState, useEffect } from 'react';
import {FaBars, FaCircle, FaPhone, FaPhoneAlt, FaUser} from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';
import { Button } from '../../components/ui/Button';
import { useNavigate } from "react-router-dom";



export const SureModal = ({visible, setVisible, msg, callback}) => {
    const navigate = useNavigate();
    let location = useLocation();

    if (visible == false)
        return null;

    return (
        <div onClick={() => setVisible(false)} className='modal'>
            <div onClick={(e) => {e.stopPropagation()}} className='modal-container-driver-picker sure-modal-container'>
                <div className='driver-picker'>
                    <div className='msg'>{msg}</div>
                    <div className='btns'>
                        <Button onClick={() => callback()} className='btn bg-green'>
                            Yes
                        </Button>
                        <Button onClick={() => setVisible(false)} className='btn bg-red'>
                            No
                        </Button>
                    </div>
                    
                </div>
            </div>
        </div>
    );
}