import React, { useContext, useEffect, useState } from 'react';
import {Bars} from 'react-icons/fa';
import { CreatingClient } from '../../api/Client';
import { LoadingContext, UserContext } from '../../context';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button } from '../../components/ui/Button';
import { InputWithLabel } from '../../components/ui/InputWithLabel';
import { Select } from '../../components/ui/Select';

export const CreateClient = () => {
    const [data, setData] = useState([]);
    const [contactName, setContactName] = useState('');
    const [passportId, setPassportId] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [comment, setComment] = useState('');
    const [lang, setLang] = useState('');
    const {user} = useContext(UserContext);
    const {setLoading} = useContext(LoadingContext);
    let location = useLocation();
    const navigate = useNavigate();

    const createClient = async(e) => {
        e.preventDefault();
        const data = {
            contact_name: contactName,
            passport_id: passportId,
            phone_number: phoneNumber,
            comment: comment,
            lang: lang,
            user_id: user.id
        };
        var resp = await CreatingClient(data);
        console.log(resp);
        if (resp?.status === 'ok')
            navigate('/clients');
        else
            console.log(resp);
    }

    /* useEffect(() => {
        const creatingClient = async() => {
            setLoading(true);
            if (user){
                const resp = await CreatingClient(location.search);
                console.log(resp);
                setData(resp);
                setLoading(false);
            }  
        }
        creatingClient();
    }, [user]) */
    return (
        <div className='default-template'>
            <div className="dt-title">Create Client</div>
            <div className="dt-body">
                <form onSubmit={createClient}>
                    <Select
                        data={Object.entries({
                            ''  : 'Select language',
                            'ru': 'Russian',
                            'ro': 'Romanian',
                            'en': 'English',
                            'he': 'Hebrew',
                            'ka': 'Georgian',
                        })}
                        label='Preffered Language'
                        placeholderFirst={true}
                        value={lang}
                        onChange={setLang}
                    />
                    <InputWithLabel
                        value={contactName}
                        onChange={setContactName}
                        label='Contact Name'
                        req={true}
                    />
                    <InputWithLabel
                        value={passportId}
                        onChange={setPassportId}
                        label='Passport ID'
                    />
                    <InputWithLabel
                        value={phoneNumber}
                        onChange={setPhoneNumber}
                        label='Phone Number'
                        req={true}
                    />
                    <InputWithLabel
                        value={comment}
                        onChange={setComment}
                        label='Comment'
                    />
                    <Button type='submit' className='btn bg-green'>
                        Create
                    </Button>
                </form>
            </div>
        </div>
    );
}