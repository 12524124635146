import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { GetClients } from '../../api/Client';
import { Button } from '../../components/ui/Button';
import { Pagination } from '../../components/ui/Pagination';
import { DoCallContext, LoadingContext, UserContext } from '../../context';
import {FaIdCard, FaShieldAlt, FaMapMarkerAlt, FaEye, FaPencilAlt, FaTrash} from 'react-icons/fa';
import { Input } from '../../components/ui/Input';
import { InputWithLabel } from '../../components/ui/InputWithLabel';
import { GetCallLog } from '../../api/Call';
import {FiPhoneIncoming, FiPhoneMissed, FiPhoneOutgoing} from 'react-icons/fi';

export const Calls = () => {
    const [data, setData] = useState([]);
    const {setLoading} = useContext(LoadingContext);
    const {user} = useContext(UserContext);
    const {doCall, setDoCall} = useContext(DoCallContext);
    let location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const getCalls = async() => {
            setLoading(true);
            if (user){
                const resp = await GetCallLog(location.search);
                console.log(resp);
                setData(resp);
                setLoading(false);
            }  
        }
        getCalls();
    }, [user, location])

    const paramsChanger = (param) => {
    }

    const findByParam = (key, param) => {
        if (key.length){
            if (key == 'Enter'){
                paramsChanger(param);
            }
        }
        else{
            paramsChanger(param);
        }
    }

    return (
        <div className='default-template'>
            <div className="dt-title">Call Log</div>
            <div className="dt-body">
                <div className="count-of-elements">
                    Found <span>{data ? data.count : '???'}</span> items.
                </div>
                <div className='table-default'>
                    <table className='table-clients'>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Manager</th>
                                <th>Client</th>
                                <th>Date</th>
                                <th>Time</th>
                                <th>Duration</th>
                                <th>Status</th>
                            </tr>
                            <tr>
                                <td>
                                </td>
                                <td>
                                </td>
                                <td>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data?.calls?.map((item, index) => {
                                    return(
                                        <tr className={'call-status-' + item.status} key={index}>
                                            <td>{item.id}</td>
                                            <td>{item.managerProfile.firstname}{item.managerProfile.firstname && ' '}{item.managerProfile.lastname}</td>
                                            <td>
                                                {item.client ? (item.client.contact_name + ' - (') : ''}
                                                <span className='phone-link call-log-link' onClick={() => setDoCall(item.phone)}>{item.phone}</span>
                                                {item.client ? ')' : ''}
                                            </td>
                                            <td className='ta-center'>{item.date}</td>
                                            <td className='ta-center'>{item.time}</td>
                                            <td className='ta-center'>{item.duration}</td>
                                            <td className='ta-center'>
                                                {item.status === '1' && <FiPhoneIncoming size={18}/>}
                                                {item.status === '2' && <FiPhoneOutgoing size={18}/>}
                                                {item.status === '3' && <FiPhoneMissed size={18}/>}
                                            </td>
                                        </tr>
                                    )
                                    
                                })
                            }
                        </tbody>
                    </table>
                </div>
                <Pagination totalCount={data && data.count > 0 && data.count} refresher={location}/>
            </div>
        </div>
    );
}