import React, { useState } from 'react';
import {FaBars, FaPhone, FaPhoneAlt, FaUser} from 'react-icons/fa';
import { Link } from 'react-router-dom';

export const Input = ({onClick, className, onChange, value, placeholder}) => {
    return (
        <input 
            value={value}
            onChange={e => {onChange(e.target.value)}}
            className={className ? className : 'input'}
            onClick={onClick}
            placeholder={placeholder}
        />
    );
}