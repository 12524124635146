import React, { forwardRef, useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { GetClients } from '../../api/Client';
import { Button } from '../../components/ui/Button';
import { Pagination } from '../../components/ui/Pagination';
import { DoCallContext, LoadingContext, UserContext } from '../../context';
import {FaIdCard, FaShieldAlt, FaMapMarkerAlt, FaTruck, FaEye, FaPencilAlt, FaTrash, FaCheckSquare, FaRegTimesCircle, FaTimesCircle, FaCheck, FaTimes, FaBan, FaFileSignature, FaIdBadge, FaCalendarAlt} from 'react-icons/fa';
import { Input } from '../../components/ui/Input';
import { InputWithLabel } from '../../components/ui/InputWithLabel';
import { DeleteShipment, GetShipments, UpdatePickup } from '../../api/Shipment';
import { DriverPicker } from '../../components/modal/DriverPicker';
import { compareAsc, format, parseISO } from 'date-fns'
import { CancelShipmentModal } from '../../components/modal/CancelShipment';
import { SureModal } from '../../components/modal/SureModal';
import DatePicker, { registerLocale } from "react-datepicker";
import enGb from 'date-fns/locale/en-GB';
registerLocale('enGb', enGb)

export const Shipments = () => {
    const {setLoading} = useContext(LoadingContext);
    const {user} = useContext(UserContext);
    const {doCall, setDoCall} = useContext(DoCallContext);
    const [data, setData] = useState(null);
    const [id, setId] = useState('');
    const [regDate, setRegDate] = useState('');
    const [shipper, setShipper] = useState('');
    const [pickup, setPickup] = useState('');
    const [pickupDate, setPickupDate] = useState('');
    const [driver, setDriver] = useState('');
    const [status, setStatus] = useState('')
    const [driverPickerVisible, setDriverPickerVisible] = useState(false);
    const [chosenShipment, setChosenShipment] = useState(0);
    const [selectedDrvier, setSelectedDriver] = useState(0);
    const [cancel, setCancel] = useState(false);
    const [sureModal, setSureModal] = useState(false);
    const [date, setDate] = useState()
    let location = useLocation();
    const navigate = useNavigate();

    const reasons = new Map([
        ['1', 'Used the services of another company'],
        ['2', 'The client changed his mind'],
        ['3', 'The client did not have time today'],
        ['4', 'The driver didn\'t have time'],
        ['5', 'Other'],
    ]);

    const CustomButtonDate = forwardRef(({ onClick, pickup_date }, ref) => (
        <Button onClick={() => {
                /* console.log(date)
                setDate(new Date(pickup_date))
                console.log(date) */
                onClick()
            }} ref={ref} className='btn-icon bg-orange'>
            <FaCalendarAlt color='white' />
        </Button>
    ))

    useEffect(() => {
        document.title = 'Shipments';
        getShipments();
        if (!location.search){
            console.log(1)
            setId('')
            setRegDate('')
            setShipper('')
            setPickup('')
            setDriver('')
        }
    }, [user, location])


    const getShipments = async() => {
        setLoading(true);
        if (user){
            const resp = await GetShipments(location.search);
            console.log(resp);
            setData(resp);
            setLoading(false);
        }  
    }

    const paramsChanger = (param) => {
        var params = new URLSearchParams(location.search);
        if (params){
            params.delete('page');
            switch (param){
                case 'shipper':
                    params.set(param, shipper);
                    break;
                case 'id':
                    params.set(param, id);
                    break;
                case 'reg_date':
                    params.set(param, regDate);
                    break;
                case 'pickup':
                    params.set(param, pickup);
                    break;
                case 'pickup_date':
                    params.set(param, pickupDate);
                    break;
                case 'driver':
                    params.set(param, driver);
                    break;
                case 'status':
                    params.set(param, status);
                    break;
            }
            navigate('/shipments?' + params);
        }
    }

    const findByParam = (key, param) => {
        if (key.length){
            if (key == 'Enter'){
                paramsChanger(param);
            }
        }
        else{
            paramsChanger(param);
        }
    }

    const statusHelper = (status) => {
        switch (status){
            case '1':
                return (<div className='like-btn status-new status'>New</div>);
            case '2':
                return (<div className='like-btn status-assigned status'>Assigned</div>);
            case '3':
                return (<div className='like-btn status-started status'>Started</div>);
            case '4':
                return (<div className='like-btn status-picked status'>Picked Up</div>);
            case '5':
                return (<div className='like-btn status-warehouse status'>At Warehouse</div>);
            case '6':
                return (<div className='like-btn status-reassign status'>Reassign</div>);
            case '7':
                return (<div className='like-btn status-canceled status'>Canceled</div>);
        }
    }

    const packageHelper = (packages) => {
        return (
            <>
                {packages &&
                    packages.map((item, index) => {
                        return (
                            <div key={index}>
                                Box {index} {item.empty === '1' && <b>- Empty</b>} {item.gift === '1' && <b>- Gift</b>} <br />
                                {item.code} {item.code && <br />}
                                {item.tracking_code ? item.tracking_code : item.additional_code} {item.tracking_code && <br />}
                                {item.consigneeInfo ? item.consigneeInfo.contact_name : ''}{item.consigneeInfo ? (' - ' + item.consigneeInfo.phone_number) : ''} {item.consigneeInfo && <br />}
                                {
                                    item.deliveryAddress &&
                                        (
                                            item.deliveryAddress.formatted_address ? item.deliveryAddress.formatted_address
                                            :
                                            item.deliveryAddress.country_code ? item.deliveryAddress.country_code
                                            :
                                            item.deliveryAddress.country
                                        )
                                }
                                {index !== packages.length - 1 && <hr />}
                            </div>
                        )
                    })
                }
            </>
        )
    }

    const blankHelper = (packages) => {
        if (packages.length){
            var blank = true;
            packages.map((item) => {
                if (item.blank){
                    if (item.blank.status !== '1')
                        blank = false;
                }
                else
                    blank = false;
            })
            return blank;
        }
        else
            return false;
    }

    const driverPicker = (id, driverId) => {
        setChosenShipment(id);
        setSelectedDriver(driverId);
        setDriverPickerVisible(true);
    }

    const CancelReasonPicker = (id) => {
        setChosenShipment(id);
        setCancel(true);
    }

    const PreRemoveShipment = (id) => {
        setChosenShipment(id);
        setSureModal(true);
    }

    const calendarPicker = async(id, date) => {
        const resp = await UpdatePickup(id, date)
        console.log(resp)
        if (resp === 1)
            getShipments()
    }

    const RemoveShipment = async() => {
        const resp = await DeleteShipment(chosenShipment, user.id);
        console.log(resp);
        if (resp === 1){
            getShipments();
            setSureModal(false);
        }
    }

    return (
        <div className='default-template'>
            <div className="dt-title">Shipments</div>
            <div className="dt-body">
                <Link to='/shipments/create'>
                    <Button className={'btn bg-green wft'}>Create Shipment</Button>
                </Link>
                <div className="count-of-elements">
                    Found <span>{data ? data.count : '???'}</span> items.
                </div>
                <div className='table-default'>
                    <table className='table-clients'>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Reg. Date</th>
                                <th>Shipper</th>
                                <th>Pickup Address</th>
                                <th>Pickup Date/Time</th>
                                <th>Boxes</th>
                                <th>Driver</th>
                                <th>System Status</th>
                                <th>Blank</th>
                                <th>Signature</th>
                                <th>1+1</th>
                                <th>Actions</th>
                            </tr>
                            <tr>
                                <td>
                                    <InputWithLabel
                                        className='input-container mb-0 w-100'
                                        value={id}
                                        onChange={setId}
                                        //onBlur={findByParam}
                                        onKeyUp={findByParam}
                                        param='id'
                                    />
                                </td>
                                <td>
                                    <InputWithLabel
                                        className='input-container mb-0 w-100'
                                        value={regDate}
                                        onChange={setRegDate}
                                        //onBlur={findByParam}
                                        onKeyUp={findByParam}
                                        param='reg_date'
                                    />
                                </td>
                                <td>
                                    <InputWithLabel
                                        className='input-container mb-0 w-100'
                                        value={shipper}
                                        onChange={setShipper}
                                        //onBlur={findByParam}
                                        onKeyUp={findByParam}
                                        param='shipper'
                                    />
                                </td>
                                <td>
                                    <InputWithLabel
                                        className='input-container mb-0 w-100'
                                        value={pickup}
                                        onChange={setPickup}
                                        //onBlur={findByParam}
                                        onKeyUp={findByParam}
                                        param='pickup'
                                    />
                                </td>
                                <td>
                                    <InputWithLabel
                                        className='input-container mb-0 w-100'
                                        value={pickupDate}
                                        onChange={setPickupDate}
                                        //onBlur={findByParam}
                                        onKeyUp={findByParam}
                                        param='pickup_date'
                                    />
                                </td>
                                <td></td>
                                <td>
                                    <InputWithLabel
                                        className='input-container mb-0 w-100'
                                        value={driver}
                                        onChange={setDriver}
                                        //onBlur={findByParam}
                                        onKeyUp={findByParam}
                                        param='driver'
                                    />
                                </td>
                                <td>
                                    <InputWithLabel
                                        className='input-container mb-0 w-100'
                                        value={status}
                                        onChange={setStatus}
                                        //onBlur={findByParam}
                                        onKeyUp={findByParam}
                                        param='status'
                                    />
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data?.shipments?.map((item, index) => {
                                    return(
                                        <tr key={index}>
                                            <td>{item.id}</td>
                                            <td>{item.registration_date} {item.registration_time}</td>
                                            <td>
                                                {item.shipperInfo.contact_name} <span className='phone-link' onClick={() => setDoCall(item.shipperInfo.phone_number)}>{item.shipperInfo.phone_number}</span>
                                                <br />
                                                <FaIdCard className='mr-5' size={20} color={item.shipperInfo.verified === '1' ? 'green' : 'red'} />
                                                <FaIdBadge className='mr-5' size={20} color={item.shipperInfo.clientAttachments.length > 0 ? 'green' : 'red'} />
                                                <FaFileSignature size={20} color={item.shipperInfo.attorney ? (item.shipperInfo.attorney.status === '1' ? '#f1c40f' : 'green') : 'red'} />
                                                {
                                                    item.shipperInfo.attorney?.date ?
                                                    <>
                                                        <br />
                                                        ({item.shipperInfo.attorney?.date})
                                                    </>
                                                    :
                                                    <></>
                                                }
                                            </td>
                                            <td>
                                                {item.pickupAddress && item.pickupAddress.city && item.pickupAddress.city + ', '}{item.pickupAddress && item.pickupAddress.city && <br/>}
                                                {item.pickupAddress && item.pickupAddress.street && item.pickupAddress.street + ', '}
                                                {item.pickupAddress && item.pickupAddress.house && 'h. ' + item.pickupAddress.house}
                                                {item.pickupAddress && item.pickupAddress.apartment && ', ' + item.pickupAddress.apartment}
                                            </td>
                                            <td>
                                                {item.pickup_date}
                                                {item.pickup_time_from && (' ' + item.pickup_time_from + ' - ' + item.pickup_time_to)}
                                                <br />
                                                <DatePicker
                                                    selected={ item.pickup_date ? new Date(item.pickup_date) : new Date()}
                                                    onChange={(date) => calendarPicker(item.id, date)}
                                                    customInput={<CustomButtonDate pickup_date={item.pickup_date}/>}
                                                    closeOnScroll={true}
                                                    dateFormat="MM/dd/yyyy h:mm aa"
                                                    locale="enGb"
                                                />
                                            </td>
                                            <td>
                                                {packageHelper(item.packagesWithAddress)}
                                            </td>
                                            <td>
                                                {
                                                    item.driverUserDataWithProfile &&
                                                        (item.driverUserDataWithProfile.userProfile.firstname + ' ' + 
                                                            item.driverUserDataWithProfile.userProfile.lastname)
                                                }
                                                {
                                                    !item.driverUserDataWithProfile ?
                                                    <Button onClick={() => driverPicker(item.id, item.driver)} className='btn-icon bg-blue'>
                                                        <FaTruck color='white'/>
                                                    </Button>
                                                    :
                                                    <Button onClick={() => driverPicker(item.id, item.driver)} className='btn-icon bg-orange'>
                                                        <FaPencilAlt color='white'/>
                                                    </Button>
                                                }
                                            </td>
                                            <td>
                                                {statusHelper(item.status)}
                                                {
                                                    item.canceled ?
                                                    <div className='ta-center mt-2'>
                                                        Canceled by: <br />
                                                        {item.canceled.manager.firstname + ' ' + item.canceled.manager.lastname} <br />
                                                        Reason: <br />
                                                        {reasons.get(item.canceled.reason)} <br />
                                                        {item.canceled.comment}
                                                    </div>
                                                    :
                                                    <></>
                                                }
                                            </td>
                                            <td>
                                                {blankHelper(item.packagesWithAddress) === true ?
                                                    <div className='like-btn bg-green'>
                                                        <FaCheck color='white'/>
                                                    </div>
                                                    :
                                                    <div className='like-btn bg-grey'>
                                                        <FaTimes color='white'/>
                                                    </div>
                                                }
                                            </td>
                                            <td>
                                                {item.signature ?
                                                    <div className='like-btn bg-green'>
                                                        <FaCheck color='white'/>
                                                    </div>
                                                    :
                                                    <div className='like-btn bg-grey'>
                                                        <FaTimes color='white'/>
                                                    </div>
                                                }
                                            </td>
                                            <td>
                                                {item.one_plus_one_action === '1' ?
                                                    <div className='like-btn bg-green'>
                                                        <FaCheck color='white'/>
                                                    </div>
                                                    :
                                                    <div className='like-btn bg-grey'>
                                                        <FaTimes color='white'/>
                                                    </div>
                                                }
                                            </td>
                                            <td>
                                                <div className='btns-actions'>
                                                    <Link>
                                                        <Button className='btn-icon bg-lightblue'>
                                                            <FaEye color='white'/>
                                                        </Button>
                                                    </Link>
                                                    <Link to={'update?id=' + item.id}>
                                                        <Button className='btn-icon bg-orange'>
                                                            <FaPencilAlt color='white'/>
                                                        </Button>
                                                    </Link>
                                                    <Button onClick={() => PreRemoveShipment(item.id)} className='btn-icon bg-red'>
                                                        <FaTrash color='white'/>
                                                    </Button>
                                                    {
                                                        item.status !== '7' ?
                                                            <Button onClick={() => CancelReasonPicker(item.id)} className='btn-icon bg-red'>
                                                                <FaBan color='white'/>
                                                            </Button>
                                                        :
                                                            <></>

                                                    }
                                                    
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
                <Pagination totalCount={data && data.count} refresher={location}/>
            </div>
            <DriverPicker userId={user.id} driver={selectedDrvier} visible={driverPickerVisible} setVisible={setDriverPickerVisible} shipmentId={chosenShipment}/>
            <CancelShipmentModal userId={user.id} visible={cancel} setVisible={setCancel} shipmentId={chosenShipment}/>
            <SureModal msg='Are you sure?' visible={sureModal} setVisible={setSureModal} callback={RemoveShipment}/>
        </div>
    );
}