import React, { useState, useEffect } from 'react';
import {FaBars, FaCircle, FaFileAlt, FaPhone, FaPhoneAlt, FaTimes, FaUser} from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';
import { Button } from '../ui/Button';
import { Select } from '../ui/Select';
import { AssignDriver } from '../../api/Shipment';
import { GetDrivers } from '../../api/User';
import { useNavigate } from "react-router-dom";
import { CreatingClient, DeleteAttach, DeleteAttorneyHtm, UploadAttach, UploadAttorneyHtm, VerifyClient } from '../../api/Client';
import { InputWithLabel } from '../ui/InputWithLabel';



export const EditAttorneyHtmModal = ({visible, setVisible, client, user, uploaded}) => {

    const [deleted, setDeleted] = useState(true);

    useEffect(() => {
        if (visible === true){
            if (client.poa !== '')
                setDeleted(false)
        }
    }, [visible])

    const uploadFile = async(e) => {
        const file = e.target.files[0];
        if (file) {
            let data = new FormData();
            data.append('file', file);
            data.append('id', client.id);
            data.append('user_id', user.id);
            const resp = await UploadAttorneyHtm(data);
            console.log(resp)
            if (resp === 1){
                uploaded();
                setVisible(false)
            }
                
        } 
    }

    const deleteFile = async() => {
        const data = {
            id: client.id,
            user_id: user.id
        }
        const resp = await DeleteAttorneyHtm(data);
        console.log(resp);
        if (resp === 1)
            uploaded();
    }

    if (visible == false)
        return null;

    return (
        <div onClick={() => setVisible(false)} className='modal'>
            <div onClick={(e) => {e.stopPropagation()}} className='modal-container-create-client'>
                <div className='driver-picker'>
                    {
                        deleted === false ?
                        <div className='verif-img-container wft'>
                            <div className='delete' onClick={() => {setDeleted(true); deleteFile()}}><FaTimes color='red' size={30} /></div>
                            <FaFileAlt color='black' size={60} />
                        </div>
                        
                    :
                        <input accept=".htm" type="file" onChange={(e) => uploadFile(e)} />
                    }
                </div>
            </div>
        </div>
    );
}