import React, { useContext, useEffect, useState } from 'react';
import {FaBars, FaPhone, FaPhoneAlt, FaUser} from 'react-icons/fa';
import { IoMdCall } from 'react-icons/io';
import { MdCallEnd } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { Button } from '../../components/ui/Button';
import { UserContext } from '../../context';
import { clearRegionNumber, findClient } from '../../helper/helper';

export const Header = ({hideSidebarMenu, incomeCalls, visible, setVisible, openCall, visibleCall, setVisibleCall, acceptCall, rejectCall, phoneNumber, setCall, setCallVisible, setLoading, client}) => {
    const [isSidebarOpen, setSidebarOpen] = useState(true);
    const { user } = useContext(UserContext);
    const [refresher, setRefresher] = useState([])
    useEffect(() => {
        /* console.log('changed----------------1')
        console.log(incomeCalls.current) */
        console.log(123512341234)
        setRefresher(incomeCalls.current)
    }, [incomeCalls.current])
    /* useEffect(() => {
        console.log(incomeCalls)
    }, [incomeCalls]) */
    const accept = (inv_id) => {
        acceptCall(inv_id);
        setVisible(false);
        setCall(true);
        setLoading(true);
    }
    const reject = (inv_id) => {
        rejectCall(inv_id);
        setVisible(false);
    }
    return (
        <header className={'main-header' + (visible === true ? ' sticky' : '')}>
            <div className='logo-text'>
                <Link to='/'>
                    FAMILIA IPS
                </Link>
            </div>
            <div className='header-main-top'>
                <div className='hmt-left'>
                    <Button
                        onClick={() => console.log(incomeCalls.current[0])}
                        className='btn-header'
                    >
                        <FaBars color='white' size={32}/>
                    </Button>
                    <Button
                        onClick={() => {openCall(true)}}
                        className='btn-header'
                    >
                        <FaPhoneAlt color='white' size={26}/>
                    </Button>
                </div>
                {/* {
                    incomeCalls.length > 0 ?
                    <div className='header-income-call'>
                        <div>
                            {client.id && (client.contact_name + ' (')}{phoneNumber}{client.id && (')')}
                        </div>
                        <div className='btns'>
                            <Button onClick={accept} className='btn-flat little-btn btn-circle bg-green'><IoMdCall size={20}/></Button>
                            <Button onClick={reject} className='btn-flat little-btn bg-red btn-circle'><MdCallEnd size={20}/></Button>
                        </div>
                    </div>
                    :
                    <></>
                } */}
                {
                    incomeCalls.current.map((item, index) => {
                        let clientText = clearRegionNumber(item.remoteIdentity._displayName)
                        if (item.client !== undefined){
                            if (item.client.id){
                                clientText = `${item.client.contact_name} (`
                                clientText += clearRegionNumber(item.remoteIdentity._displayName)
                                clientText += ')'
                            }
                        }
                            
                        return(
                            <div key={index} className='header-income-call'>
                                <div>
                                    {/* {client !== null && (client.contact_name + ' (')}{item._displayName}{client !== null && (')')} */}
                                    {clientText}
                                </div>
                                <div className='btns'>
                                    <Button onClick={() => accept(index)} className='btn-flat little-btn btn-circle bg-green'><IoMdCall size={20}/></Button>
                                    <Button onClick={() => reject(index)} className='btn-flat little-btn bg-red btn-circle'><MdCallEnd size={20}/></Button>
                                </div>
                            </div>
                        )
                        
                    })
                }
                <div className='hmt-right'>
                    <Link to='/profile' className='btn-header'>
                        <FaUser color='white' size={24}/>
                        <span className='capitalize'>{user ? user.username : ''}</span>
                    </Link>
                </div>
                {/* <div className='header-main-bottom'></div> */}
            </div>
        </header>
    );
}