import React, { useContext } from 'react';
import { UserContext } from '../../context';

export const SidebarItem = ({className, role, children}) => {
    const {user} = useContext(UserContext);

    if (role)
        if (user.role !== role)
            return null;

    return <li className={className ? className : ''}>{children}</li>
}