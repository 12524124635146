import React, { forwardRef, useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button } from '../../components/ui/Button';
import { Pagination } from '../../components/ui/Pagination';
import { LoadingContext, UserContext } from '../../context';
import {FaIdCard, FaShieldAlt, FaMapMarkerAlt, FaEye, FaPencilAlt, FaTrash, FaCalendarAlt} from 'react-icons/fa';
import { Input } from '../../components/ui/Input';
import { InputWithLabel } from '../../components/ui/InputWithLabel';
import { GACreatedShipments, GetQtyBoxes } from '../../api/Analitics';
import DatePicker from "react-datepicker";
import { InputLabelAndChildren } from '../../components/ui/InputLabelAndChildren';
import { compareAsc, format } from 'date-fns'

export const HowManyBoxes = () => {
    const {setLoading} = useContext(LoadingContext);
    const {user} = useContext(UserContext);
    const [data, setData] = useState(null);
    const [id, setId] = useState('');
    const [date, setDate] = useState(new Date());
    let location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const getData = async() => {
            setLoading(true);
            if (user){
                const resp = await GetQtyBoxes(location.search);
                console.log(resp);
                setData(resp);
                setLoading(false);
            }  
        }
        getData();
    }, [user, location])

    const dateChanger = (newDate) => {
        setDate(newDate);
        paramsChanger('date', newDate);
    }

    const paramsChanger = (param, newDate) => {
        var params = new URLSearchParams(location.search);
        if (params){
            params.delete('page');
            switch (param){
                case 'date':
                    params.set(param, format(newDate, 'yyyy-MM-dd'));
                    break;
            }
            navigate('/analitics/created-shipments?' + params);
        }
    }

    const findByParam = (key, param) => {
        if (key.length){
            if (key == 'Enter'){
                paramsChanger(param);
            }
        }
        else{
            paramsChanger(param);
        }
    }

    return (
        <div className='default-template'>
            <div className="dt-title">Analytics: how many boxes</div>
            <div className="dt-body">
                {/* <div className="count-of-elements">
                    Found <span>{data ? data.count : '???'}</span> items.
                </div> */}
                <div className='table-default'>
                    <table className='table-clients'>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Name</th>
                                <th>Phone number</th>
                                <th>Empty</th>
                                <th>Packages</th>
                                <th>Actions</th>
                            </tr>
                            <tr>
                                <td>
                                {/* <InputWithLabel
                                        className='input-container mb-0 w-100'
                                        value={id}
                                        onChange={setId}
                                        onBlur={findByParam}
                                        onKeyUp={findByParam}
                                        param='id'
                                    /> */}
                                </td>
                                <td>
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data?.opers?.map((item, index) => {
                                    return(
                                        <tr key={index}>
                                            <td>{item.client.id}</td>
                                            <td>{item.client.contact_name}</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    )
                                    
                                })
                            }
                        </tbody>
                    </table>
                </div>
                <Pagination totalCount={data?.opers?.length > 20 ? 21 : 1} refresher={location}/>
            </div>
        </div>
    );
}