import React, { useState, useEffect } from 'react';
import {FaBars, FaCircle, FaPhone, FaPhoneAlt, FaUser} from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';
import { Button } from '../ui/Button';
import { Select } from '../ui/Select';
import { AssignDriver } from '../../api/Shipment';
import { GetDrivers } from '../../api/User';
import { useNavigate } from "react-router-dom";
import { CreateContactPerson, CreatingClient } from '../../api/Client';
import { InputWithLabel } from '../ui/InputWithLabel';



export const CreateContactPersonModal = ({visible, setVisible, clientId, callback, person, setPerson}) => {

    const [contactName, setContactName] = useState('');
    const [passportId, setPassportId] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [comment, setComment] = useState('');
    const [id, setId] = useState(0)

    useEffect(() => {
        setContactName('');
        setPassportId('');
        setPhoneNumber('');
        setComment('');
        if (visible === true && Object.keys(person).length > 0){
            //console.log(person)
            setContactName(person.contact_name)
            setPassportId(person.passport_id)
            setPhoneNumber(person.phone_number)
            setComment(person.comment)
            setId(person.id)
        }
        else{
            setPerson({})
            //console.log(13)
        }
            
    }, [visible])

    const createContactPerson = async(e) => {
        e.preventDefault();
        if (contactName.length){
            const data = {
                contact_name: contactName,
                passport_id: passportId,
                phone_number: phoneNumber,
                comment: comment,
                client_id: clientId,
                id
            };
            var resp = await CreateContactPerson(data);
            console.log(resp);
            if (resp.status){
                callback();
                setVisible(false)
            }
            else
                console.log(resp);
        }
    }

    if (visible == false)
        return null;

    return (
        <div onClick={() => setVisible(false)} className='modal'>
            <div onClick={(e) => {e.stopPropagation()}} className='modal-container-create-client'>
                <div className='driver-picker'>
                    <form onSubmit={createContactPerson}>
                        <InputWithLabel
                            value={contactName}
                            onChange={setContactName}
                            label='Contact Name'
                        />
                        <InputWithLabel
                            value={passportId}
                            onChange={setPassportId}
                            label='Passport ID'
                        />
                        <InputWithLabel
                            value={phoneNumber}
                            onChange={setPhoneNumber}
                            label='Phone Number'
                        />
                        <div className="input-container">
                            <div className="title">Comment</div>
                            <textarea value={comment} onChange={(e) => setComment(e.target.value)}></textarea>
                        </div>
                        
                        <Button type='submit' className='btn bg-green'>
                            {id !== 0 ? 'Update' : 'Create'}
                        </Button>
                    </form>
                </div>
            </div>
        </div>
    );
}