import React, { useState, useEffect } from 'react';
import {FaBars, FaBoxes, FaCheck, FaCircle, FaMicrophone, FaMicrophoneSlash, FaPhone, FaPhoneAlt, FaTimes, FaUser} from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { Button } from '../../components/ui/Button';
import { IoMdCall } from "react-icons/io";
import { MdCallEnd } from "react-icons/md";
import { Input } from '../../components/ui/Input';
import { format } from 'date-fns'
import { GetClientByPhone } from '../../api/Client';
import { clearRegionNumber } from '../../helper/helper';

export const OutgoingCall = ({visible, setVisible, makeCall, phoneNumber, setPhoneNumber, endCall, call, setCall, mute, phone, timer, client, status, inviter, fresh}) => {

    //const [status, setStatus] = useState(true);
    const [muted, setMuted] = useState(false);
    const [time, setTime] = useState(0);
    const [refresher, setRefresher] = useState(false)

    const end = () => {
        endCall();
        setVisible(false);
        setCall(false);
    }
    const muteMic = () => {
        setMuted(!muted);
        mute();
    }

    useEffect(() => {
        setTime(format(new Date(timer * 1000), 'mm:ss'));
    }, [timer])

    useEffect(() => {
        console.log(refresher)
        setRefresher(!refresher)
        console.log(inviter.current)
    }, [inviter.current])

    useEffect(() => {
        console.log('freeeeeeeeeeeeeesh:', fresh)
    }, [fresh])

    if (visible == false)
        return null;

    return (
        <div onClick={() => {setVisible(false)}} className='modal'>
            {
                !call ?
                <form onSubmit={(e) => e.preventDefault()} onClick={(e) => {e.stopPropagation()}} className='modal-container-outgoing-call'>
                    <div>
                        <div className='ogc-status'>
                            Status: {status === 'Connected' ? <FaCheck color='green'/> : <FaTimes color='red'/>}
                        </div>
                        <Input value={phoneNumber} onChange={setPhoneNumber} type="text" placeholder='Type number'/>
                    </div>
                    
                    <Button type={'submit'} onClick={() => {
                        makeCall();
                        }}
                        className='btn-circle'>
                            <IoMdCall size={42}/>
                    </Button>
                </form>
                :
                <div onClick={(e) => {e.stopPropagation()}} className='modal-container-call'>
                    <div className='circle-user'>
                        <FaUser color='white' size={120}/>
                    </div>
                    <div className='user-number'>{inviter?.current?.client?.id && (inviter?.current?.client?.contact_name + ' (')}{clearRegionNumber(inviter?.current?.remoteIdentity.uri.normal.user)}{inviter?.current?.client?.id && (')')}</div>
                    <div className='user-number'>{time}</div>
                    <div className="calls-btns">
                        <Link to={inviter?.current?.client?.id ? ('/clients/view?id=' + inviter?.current?.client?.id) : ('/clients/create?phone=' + clearRegionNumber(inviter?.current?.remoteIdentity.uri.normal.user))} className='btn-circle'>
                            <FaUser size={32}/>
                        </Link>
                        
                        <Link className='btn-circle' to={'/shipments?shipper=' + clearRegionNumber(inviter?.current?.remoteIdentity.uri.normal.user)}>
                            <FaBoxes size={32}/>
                        </Link>
                        
                        <Button onClick={muteMic} className='btn-circle'>
                            {
                                !muted ?
                                    <FaMicrophone size={32}/>
                                    :
                                    <FaMicrophoneSlash size={32}/>
                            }
                        </Button>
                        <Button onClick={end} className='bg-red btn-circle'><MdCallEnd size={32}/></Button>
                    </div>
                </div>
            }
            
        </div>
    );
}