export const Call = async (user_id, client, date, time, duration, status) => {
    const data = {
        "user_id": user_id,
        "client": client,
        "date": date,
        "time": time,
        "duration": duration,
        "status": status,
    }
    console.log(localStorage.getItem('token'))
    var resp = await fetch('https://crm.familiaips.com/api/web/v1/recrm/call', {
        method: 'post',
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    })
        .then((response) => {
            //console.log(response.json())
            return response.json()
        })
        .catch((error) => {
            console.log(error)
            return {error: 'true'}
        });
    return resp;
    
}

export const GetCallLog = async (params) => {
    if (!params.length)
        params = '';
    var resp = await fetch(`https://crm.familiaips.com/api/web/v1/recrm/get-call-log${params}`, {
        method: 'get',
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('token')
        },
    })
        .then((response) => {
            //console.log(response.json())
            return response.json()
        })
        .catch((error) => {
            console.log(error)
            return {error: 'true'}
        });
    return resp;
    
}