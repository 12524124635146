export const GetShipments = async (params, method) => {
    //const params = 
    if (!params.length)
        params = '';
    var resp = await fetch(`https://crm.familiaips.com/api/web/v1/recrm/get-shipments${params}`, {
        method: 'get',
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('token')
        },
    })
        .then((response) => {
            //console.log(response.json())
            return response.json()
        })
        .catch((error) => {
            console.log(error)
            return {error: 'true'}
        });
    return resp;
    
}
export const GetShipment = async (params, method) => {
    //const params = 
    if (!params.length)
        params = '';
    var resp = await fetch(`https://crm.familiaips.com/api/web/v1/recrm/get-shipment${params}`, {
        method: 'get',
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('token')
        },
    })
        .then((response) => {
            //console.log(response.json())
            return response.json()
        })
        .catch((error) => {
            console.log(error)
            return {error: 'true'}
        });
    return resp;
    
}
export const AssignDriver = async (driverId, shipmentId, userId) => {
    const data = {
        "shipment": shipmentId,
        "driver": driverId,
        "user_id": userId
    }
    console.log(data);
    var resp = await fetch(`https://crm.familiaips.com/api/web/v1/recrm/assign-driver`, {
        method: 'post',
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    })
        .then((response) => {
            //console.log(response.json())
            return response.json()
        })
        .catch((error) => {
            console.log(error)
            return {error: 'true'}
        });
    return resp;
    
}
export const UpdatePickup = async (id, date) => {
    const data = {
        id,
        date
    }
    var resp = await fetch(`https://crm.familiaips.com/api/web/v1/recrm/update-pickup-shipment`, {
        method: 'post',
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    })
        .then((response) => {
            //console.log(response.json())
            return response.json()
        })
        .catch((error) => {
            console.log(error)
            return {error: 'true'}
        });
    return resp;
    
}
export const CancelShipment = async (reasonId, shipmentId, comment, userId) => {
    const data = {
        "shipment": shipmentId,
        "reason": reasonId,
        "comment": comment,
        "user_id": userId
    }
    console.log(data);
    var resp = await fetch(`https://crm.familiaips.com/api/web/v1/recrm/cancel-shipment`, {
        method: 'post',
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    })
        .then((response) => {
            //console.log(response.json())
            return response.json()
        })
        .catch((error) => {
            console.log(error)
            return {error: 'true'}
        });
    return resp;
    
}
export const DeleteShipment = async (shipmentId, userId) => {
    const data = {
        "id": shipmentId,
        "user_id": userId
    }
    console.log(data);
    var resp = await fetch(`https://crm.familiaips.com/api/web/v1/recrm/delete-shipment`, {
        method: 'post',
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    })
        .then((response) => {
            //console.log(response.json())
            return response.json()
        })
        .catch((error) => {
            console.log(error)
            return {error: 'true'}
        });
    return resp;
    
}
export const CreateNewShipment = async (data) => {
    var resp = await fetch(`https://crm.familiaips.com/api/web/v1/recrm/create-shipment`, {
        method: 'post',
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    })
        .then((response) => {
            //console.log(response.json())
            return response.json()
        })
        .catch((error) => {
            console.log(error)
            return {error: 'true'}
        });
    return resp;
    
}
export const UpdateThatShipment = async (data) => {
    var resp = await fetch(`https://crm.familiaips.com/api/web/v1/recrm/update-shipment`, {
        method: 'post',
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    })
        .then((response) => {
            //console.log(response.json())
            return response.json()
        })
        .catch((error) => {
            console.log(error)
            return {error: 'true'}
        });
    return resp;
    
}
export const GetCountries = async () => {
    var resp = await fetch(`https://crm.familiaips.com/api/web/v1/recrm/get-countries`, {
        method: 'get',
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('token')
        },
    })
        .then((response) => {
            //console.log(response.json())
            return response.json()
        })
        .catch((error) => {
            console.log(error)
            return {error: 'true'}
        });
    return resp;
    
}