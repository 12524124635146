import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { DeleteClient, GetClients } from '../../api/Client';
import { Button } from '../../components/ui/Button';
import { Pagination } from '../../components/ui/Pagination';
import { LoadingContext, UserContext } from '../../context';
import {FaIdCard, FaShieldAlt, FaMapMarkerAlt, FaEye, FaPencilAlt, FaTrash} from 'react-icons/fa';
import { Input } from '../../components/ui/Input';
import { InputWithLabel } from '../../components/ui/InputWithLabel';
import { SureModal } from '../../components/modal/SureModal';

export const Clients = () => {
    const {setLoading} = useContext(LoadingContext);
    const {user} = useContext(UserContext);
    const [data, setData] = useState(null);
    const [id, setId] = useState('');
    const [contactName, setContactName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [passport, setPassport] = useState('');
    const [refNumber, setRefNumber] = useState('');
    const [sureModal, setSureModal] = useState(false);
    const [chosenClient, setChosenClient] = useState(0);
    let location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        getClients();
        document.title = 'Clients';
    }, [user, location])

    const getClients = async() => {
        setLoading(true);
        if (user){
            const resp = await GetClients(location.search);
            console.log(resp);
            setData(resp);
            setLoading(false);
        }  
    }

    const paramsChanger = (param) => {
        var params = new URLSearchParams(location.search);
        if (params){
            params.delete('page');
            switch (param){
                case 'id':
                    params.set(param, id);
                    break;
                case 'name':
                    params.set(param, contactName);
                    break;
                case 'phone':
                    params.set(param, phoneNumber);
                    break;
                case 'passport':
                    params.set(param, passport);
                    break;
                case 'ref_number':
                    params.set(param, refNumber);
                    break;
            }
            navigate('/clients?' + params);
        }
    }

    const findByParam = (key, param) => {
        if (key.length){
            if (key == 'Enter'){
                paramsChanger(param);
            }
        }
        else{
            paramsChanger(param);
        }
    }

    const PreRemoveClient = (id) => {
        console.log(id);
        setChosenClient(id);
        setSureModal(true);
    }

    const RemoveClient = async() => {
        const resp = await DeleteClient(chosenClient, user.id);
        console.log(resp);
        if (resp === 1){
            getClients();
            setSureModal(false);
        }
    }

    return (
        <div className='default-template'>
            <div className="dt-title">Clients</div>
            <div className="dt-body">
                <Link to='/clients/create'>
                    <Button className={'btn bg-green wft'}>Create Client</Button>
                </Link>
                <div className="count-of-elements">
                    Found <span>{data ? data.count : '???'}</span> items.
                </div>
                <div className='table-default'>
                    <table className='table-clients'>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Contact Name</th>
                                <th>Phone Number</th>
                                <th>Passport</th>
                                <th>Ref Number</th>
                                <th>Verified</th>
                                <th>Reliable</th>
                                <th>Actions</th>
                            </tr>
                            <tr>
                                <td>
                                    <InputWithLabel
                                        className='input-container mb-0 w-100'
                                        value={id}
                                        onChange={setId}
                                        //onBlur={findByParam}
                                        onKeyUp={findByParam}
                                        param='id'
                                    />
                                </td>
                                <td>
                                    <InputWithLabel
                                        className='input-container mb-0 w-100'
                                        value={contactName}
                                        onChange={setContactName}
                                        //onBlur={findByParam}
                                        onKeyUp={findByParam}
                                        param='name'
                                    />
                                </td>
                                <td>
                                    <InputWithLabel
                                        className='input-container mb-0 w-100'
                                        value={phoneNumber}
                                        onChange={setPhoneNumber}
                                        //onBlur={findByParam}
                                        onKeyUp={findByParam}
                                        param='phone'
                                    />
                                </td>
                                <td>
                                    <InputWithLabel
                                        className='input-container mb-0 w-100'
                                        value={passport}
                                        onChange={setPassport}
                                        //onBlur={findByParam}
                                        onKeyUp={findByParam}
                                        param='passport'
                                    />
                                </td>
                                <td>
                                    {/* <InputWithLabel
                                        className='input-container mb-0 w-100'
                                        value={refNumber}
                                        onChange={setRefNumber}
                                        //onBlur={findByParam}
                                        onKeyUp={findByParam}
                                        param='ref_number'
                                    /> */}
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data &&
                                    data.clients &&
                                        data.clients.map((item, index) => {
                                            return(
                                                <tr key={index}>
                                                    <td>{item.id}</td>
                                                    <td>{item.contact_name}</td>
                                                    <td>{item.phone_number}</td>
                                                    <td>{item.passport_id}</td>
                                                    <td>{item.attorney?.ref_number}</td>
                                                    <td className='ta-center'>
                                                        <FaIdCard size={28} color={item.verified === '1' ? 'green' : 'red'} />
                                                    </td>
                                                    <td className='ta-center'>
                                                        <FaShieldAlt size={28} color={item.reliable === '1' ? '#ccc' : 'red'} />
                                                    </td>
                                                    <td className='btns-actions'>
                                                        <Link>
                                                            <Button className='btn-icon bg-green'>
                                                                <FaMapMarkerAlt color='white'/>
                                                            </Button>
                                                        </Link>
                                                        <Link to={'/clients/view?id=' + item.id}>
                                                            <Button className='btn-icon bg-lightblue'>
                                                                <FaEye color='white'/>
                                                            </Button>
                                                        </Link>
                                                        <Link to={'/clients/update?id=' + item.id}>
                                                            <Button className='btn-icon bg-orange'>
                                                                <FaPencilAlt color='white'/>
                                                            </Button>
                                                        </Link>
                                                        <Button onClick={() => PreRemoveClient(item.id)} className='btn-icon bg-red'>
                                                            <FaTrash color='white'/>
                                                        </Button>
                                                    </td>
                                                </tr>
                                            )
                                            
                                        })
                            }
                        </tbody>
                    </table>
                </div>
                <Pagination totalCount={data && data.count} refresher={location}/>
            </div>
            <SureModal msg='Are you sure?' visible={sureModal} setVisible={setSureModal} callback={RemoveClient}/>
        </div>
    );
}






/* <div className='table-default'>
                    <table>
                        <thead>
                            <tr>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div> */