import React, { useContext, useEffect, useState } from 'react';
import {Bars} from 'react-icons/fa';
import { GetClient } from '../../api/Client';
import { LoadingContext, UserContext } from '../../context';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button } from '../../components/ui/Button';

export const Client = () => {
    const [data, setData] = useState([]);

    const {user} = useContext(UserContext);
    const {setLoading} = useContext(LoadingContext);
    let location = useLocation();

    useEffect(() => {
        const getClient = async() => {
            setLoading(true);
            if (user){
                const resp = await GetClient(location.search);
                console.log(resp);
                setData(resp);
                setLoading(false);
            }  
        }
        getClient();
    }, [user])
    return (
        <div className='default-template'>
            {
                data &&
                    !data.id ?
                        <div className="got-error">{data}</div>
                    :
                    <>
                        <div className="dt-title">{data.contact_name && data.contact_name} - {data.phone_number ? data.phone_number : 'no phone number'}</div>
                        <div className="dt-body">
                            <div className='btns mb-10'>
                                <Link className='mr-5' to={'/clients/update?id=' + data.id}>
                                    <Button className={'btn wft'}>Update</Button>
                                </Link>
                                
                                {/* <Button className={'btn bg-red wft'}>Delete</Button> */}
                            </div>
                            <div className='table-default'>
                                <table className='table-clients'>
                                    <tbody>
                                        <tr>
                                            <td>ID</td>
                                            <td>{data && data.id}</td>
                                        </tr>
                                        <tr>
                                            <td>Individual</td>
                                            <td>{data && data.individual}</td>
                                        </tr>
                                        <tr>
                                            <td>Contact Name</td>
                                            <td>{data && data.contact_name}</td>
                                        </tr>
                                        <tr>
                                            <td>Phone Number</td>
                                            <td>{data && data.phone_number}</td>
                                        </tr>
                                        <tr>
                                            <td>Company</td>
                                            <td>{data && data.company}</td>
                                        </tr>
                                        <tr>
                                            <td>Client Id Number</td>
                                            <td>{data && data.client_id_number}</td>
                                        </tr>
                                        <tr>
                                            <td>Legal Entity</td>
                                            <td>{data && data.legal_entity}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </>
                    
            }
            
        </div>
    );
}