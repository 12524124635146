import React, { useState } from 'react';
import {FaBars, FaCircle, FaPhone, FaPhoneAlt, FaUser} from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { Button } from '../../components/ui/Button';
import { IoMdCall } from "react-icons/io";
import { MdCallEnd } from "react-icons/md";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { Oval, TailSpin } from 'react-loader-spinner';


export const Loading = ({visible, setVisible}) => {


    if (visible == false)
        return null;

    return (
        <div onClick={() => setVisible(false)} className='modal'>
            <div onClick={(e) => {e.stopPropagation()}} className='modal-loading'>
                <Oval
                    height={100}
                    width={100}
                    color="#3c8dbc"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel='oval-loading'
                    secondaryColor="#367fa9"
                    strokeWidth={2}
                    strokeWidthSecondary={2}

                />
            </div>
        </div>
    );
}