import React from 'react';

export const InputLabelAndChildren = ({onClick, disabled, checked, className, inputClassName, onChange, value, placeholder, children, id, type, name, onBlur, onKeyUp, param, label}) => {
    return (
        <label className={className ? className : 'input-container'}>
            {
                label ?
                    <div className='title'>{label}</div>
                    :
                    <></>
            }
            <div className='inp'>
                <input
                    disabled={disabled}
                    name={name}
                    type={type ? type : 'text'}
                    value={value}
                    onChange={e => {onChange(e.target.value)}}
                    onClick={onClick}
                    placeholder={placeholder}
                    id={id}
                    onBlur={() => {onBlur && onBlur('', param)}}
                    onKeyUp={e => {onKeyUp && onKeyUp(e.key, param)}}
                    className={inputClassName}
                    checked={checked}
                />
                {children}
            </div>
        </label>
    );
}