import React, { useState, useEffect } from 'react';
import {FaBars, FaCircle, FaFileAlt, FaPhone, FaPhoneAlt, FaTimes, FaUser} from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';
import { Button } from '../ui/Button';
import { Select } from '../ui/Select';
import { AssignDriver } from '../../api/Shipment';
import { GetDrivers } from '../../api/User';
import { useNavigate } from "react-router-dom";
import { CreatingClient, DeleteAttach, UpdateAttorney, UploadAttach, VerifyClient } from '../../api/Client';
import { InputWithLabel } from '../ui/InputWithLabel';
import { InputRadioBox } from '../ui/InputRadioBox';



export const EditAttorneyPdfModal = ({visible, setVisible, client, user, uploaded}) => {

    const [date, setDate] = useState('');
    const [refNumber, setRefNumber] = useState('');
    const [printed, setPrinted] = useState(false);
    const [file, setFile] = useState({});
    const [deleted, setDeleted] = useState(true);

    useEffect(() => {
        if (visible === true)
            console.log(client)
    }, [client])

    useEffect(() => {
        if (visible === true){
            if (client.attorney){
                setDate(client.attorney?.date);
                setRefNumber(client.attorney?.ref_number);
            }
            
            setPrinted(client.attorney?.status === '2' ? false : true);
            if (client.attorney?.local_name)
                setDeleted(false);
        }
    }, [visible])


    const update = async() => {
        if (date !== '' && refNumber !== ''){
            let data = new FormData();
            data.append('id', client.id);
            data.append('user_id', user.id);
            data.append('date', date);
            data.append('ref_number', refNumber);
            data.append('status', printed === false ? 2 : 1);
            if (deleted === true && file)
                data.append('file', file);
            const resp = await UpdateAttorney(data);
            console.log(resp);
            if (resp === 1){
                uploaded();
                setVisible(false);
            }
            else
                alert("Something went wrong. The changes will not be saved");
        }
        else
            alert('Type date of termination and reference number')
    }

    if (visible == false)
        return null;

    return (
        <div onClick={() => setVisible(false)} className='modal'>
            <div onClick={(e) => {e.stopPropagation()}} className='modal-container-create-client'>
                <div className='driver-picker'>
                    <InputWithLabel
                        label='Date of termination'
                        req={true}
                        value={date}
                        onChange={setDate}
                    />
                    <InputWithLabel
                        label='Reference number'
                        req={true}
                        value={refNumber}
                        onChange={setRefNumber}
                    />
                    <InputRadioBox
                        className={`promo-card ${printed === true ? 'active' : '' } w-100 mt-10`} type='checkbox' value='1'
                        name='one_plus_one' inputClassName='hidden'
                        onChange={setPrinted}
                        checked={printed}
                    >
                        Only printed
                    </InputRadioBox>
                    {
                        deleted === false ?
                            <div className='verif-img-container wft mt-20'>
                                <div className='delete' onClick={() => setDeleted(true)}><FaTimes color='red' size={30} /></div>
                                <FaFileAlt color='black' size={60} />
                            </div>
                            
                        :
                            <input className='mt-20' accept=".pdf" type="file" onChange={(e) => setFile(e.target.files[0])} />
                    }
                    <form className='mt-10'>
                        <Button onClick={() => update()} className='btn bg-green mr-5'>
                            Update
                        </Button>
                    </form>
                </div>
            </div>
        </div>
    );
}