import React, { useState, useEffect } from 'react';
import {FaBars, FaCircle, FaPhone, FaPhoneAlt, FaTimes, FaUser} from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';
import { Button } from '../ui/Button';
import { Select } from '../ui/Select';
import { AssignDriver } from '../../api/Shipment';
import { GetDrivers } from '../../api/User';
import { useNavigate } from "react-router-dom";
import { CreatingClient, DeleteAttach, UploadAttach, VerifyClient } from '../../api/Client';
import { InputWithLabel } from '../ui/InputWithLabel';



export const ClientVerifyModal = ({visible, setVisible, client, user, uploaded}) => {

    const [name, setName] = useState('')
    const [id, setId] = useState('') //passport id
    const [expire, setExpire] = useState('')

    useEffect(() => {
        if (visible === true)
            console.log(client)
        setName(client.contact_name)
        setId(client.passport_id)
        setExpire(client.passport_expired)
    }, [client])

    const uploadFile = async(e) => {
        const file = e.target.files[0];
        if (file) {
            let data = new FormData();
            data.append('file', file);
            data.append('id', client.id);
            data.append('user_id', user.id);
            console.log(user.id, client.id)
            const resp = await UploadAttach(data);
            console.log(resp)
            if (resp === 1)
                uploaded();
        } 
    }

    const deleteFile = async() => {
        const data = {
            id: client.clientAttachments[0].id,
            user_id: user.id,
        }
        const resp = await DeleteAttach(data);
        console.log(resp);
        if (resp === 1)
            uploaded();
    }

    const verify = async(status) => {
        if (status === 1){
            if (name !== '' && id !== '' && expire !== '' && client.clientAttachments.length){
                const data = {
                    id: client.id,
                    verify: status,
                    user_id: user.id,
                    contact_name: name,
                    passport_id: id,
                    passport_expired: expire
                }
                const resp = await VerifyClient(data);
                console.log(resp);
                if (resp === 1){
                    uploaded();
                    setVisible(false);
                }
            }
            else{
                alert('Add passport image, passport id, contact name and date of expire of passport');
            }
        }
        else{
            const data = {
                id: client.id,
                verify: status,
                user_id: user.id,
                contact_name: name,
                passport_id: id,
                passport_expired: expire
            }
            const resp = await VerifyClient(data);
            console.log(resp);
            if (resp === 1){
                uploaded();
                setVisible(false);
            }
        }
    }

    if (visible == false)
        return null;

    return (
        <div onClick={() => setVisible(false)} className='modal'>
            <div onClick={(e) => {e.stopPropagation()}} className='modal-container-create-client w-fit-content minw-700'>
                <div className='two-columns'>
                    <form className='mt-10 client-inputs-container'>
                        <div>
                            <InputWithLabel
                                className='input-container mb-0 w-100'
                                label='Name'
                                req={true}
                                value={name}
                                onChange={setName}
                            />
                            <InputWithLabel
                                className='input-container mb-0 w-100'
                                label='ID'
                                req={true}
                                value={id}
                                onChange={setId}
                            />
                            <InputWithLabel
                                className='input-container mb-10 w-100'
                                label='Expired'
                                placeholder='dd/mm/yyyy'
                                req={true}
                                value={expire}
                                onChange={setExpire}
                            />
                        </div>
                        {
                            client?.verified !== '1' ?
                                <Button onClick={() => verify(1)} className='btn bg-green mt-auto'>
                                    Verify
                                </Button>
                            :
                            <></>
                        }
                        {
                            client?.verified === '1' ?
                                <Button onClick={() => verify(0)} className='btn bg-red mt-auto'>
                                    Unverify
                                </Button>
                            :
                            <></>
                        }
                        
                        
                    </form>
                    {
                        client?.clientAttachments?.length ?
                            <div className='verif-img-container'>
                                <div className='delete' onClick={deleteFile}><FaTimes color='red' size={60} /></div>
                                <img className='verif-img' src={client.clientAttachments[0].base_url + client.clientAttachments[0].path} alt="" />
                            </div>
                            
                        :
                            <div className='center-of-center w-70'>
                                <input accept=".png, .jpg, .jpeg" type="file" onChange={(e) => uploadFile(e)} />
                            </div> 
                    }
                </div>
            </div>
        </div>
    );
}