import React, { useEffect, useState } from 'react';
import {Bars} from 'react-icons/fa';
import { Auth } from '../api/User';
import { Button } from '../components/ui/Button';
import { UserContext } from '../context';
import { useNavigate } from 'react-router-dom';
import { Loading } from '../layout/components/Loading';

export const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [user, setUser] = useState(null);
    const [error, setError] = useState(0); //0 - ok, 1 - empty username or password, 2 - incorrect login or password, 3 - another error
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        checkAuth()
    }, [])

    const checkAuth = async () => {
        if (localStorage.getItem('token')){
            navigate('/', true)
        }
    }

    const checkInputs = () => {
        if (username.length === 0 || password.length === 0){
            setError(1);
            return 1;
        }
        else
            return 0;
    }

    const auth = async(e) => {
        e.preventDefault();
        const err = checkInputs();
        setLoading(true);
        if (err === 0){
            const auth = JSON.parse(await Auth(username, password));
            if (auth.token){
                setError(0);
                localStorage.setItem('token', auth.token);
                navigate('/');
            }
            else if (auth === 0){
                setError(2);
            }
                
            console.log(auth);
        }
        setLoading(false);
    }
    return (
        <UserContext.Provider value={{
            user,
            setUser
        }}>
        <div className='login-page'>
            <div className='login-container'>
                <div className='lc-label'>Login</div>
                <form onSubmit={auth} className="lc-box">
                    <div>
                        <label htmlFor="username">Username</label>
                        <input className={error === 1 ? username.length === 0 ? 'border-error' : '' : ''} onChange={e => setUsername(e.target.value)} value={username} id='username' type="text" />
                        {
                            error === 1 ? username.length === 0 ? <span className='error'>Can't be empty</span> : <></> : <></>
                        }
                        
                    </div>
                    <div>
                        <label htmlFor="password">Password</label>
                        <input className={error === 1 ? password.length === 0 ? 'border-error' : '' : ''} onChange={e => setPassword(e.target.value)} value={password} id='password' type="password" />
                        {
                            error === 1 ? password.length === 0 ? <span className='error'>Can't be empty</span> : <></> : <></>
                        }
                    </div>

                    {
                        error === 2 ? <span className='error'>Incorrect username or password</span> : <></>
                    }
                    
                    <label className='remember' htmlFor="remember">
                        <input readOnly checked id='remember' type="checkbox" />
                        Remember me
                    </label>
                    
                    <Button type={'submit'}>Login</Button>
                    
                </form>
            </div>
            <Loading
                setVisible={setLoading}
                visible={loading}
            />
        </div>
        </UserContext.Provider>
    );
}