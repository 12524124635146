export const GACreatedShipments = async (params, method) => {
    //const params = 
    if (!params.length)
        params = '';
    var resp = await fetch(`https://crm.familiaips.com/api/web/v1/recrm/analitics-created-shipments${params}`, {
        method: 'get',
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('token')
        },
    })
        .then((response) => {
            //console.log(response.json())
            return response.json()
        })
        .catch((error) => {
            console.log(error)
            return {error: 'true'}
        });
    return resp;
    
}
export const GASentPoaHtm = async (params, method) => {
    //const params = 
    if (!params.length)
        params = '';
    var resp = await fetch(`https://crm.familiaips.com/api/web/v1/recrm/analitics-sent-poa-htm${params}`, {
        method: 'get',
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('token')
        },
    })
        .then((response) => {
            //console.log(response.json())
            return response.json()
        })
        .catch((error) => {
            console.log(error)
            return {error: 'true'}
        });
    return resp;
    
}

export const GAEmptyBoxes= async (params, method) => {
    //const params = 
    if (!params.length)
        params = '';
    var resp = await fetch(`https://crm.familiaips.com/api/web/v1/recrm/empty-boxes${params}`, {
        method: 'get',
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('token')
        },
    })
        .then((response) => {
            //console.log(response.json())
            return response.json()
        })
        .catch((error) => {
            console.log(error)
            return {error: 'true'}
        });
    return resp;
    
}

export const GetQtyBoxes = async (params, method) => {
    if (!params.length)
        params = '';
    var resp = await fetch(`https://crm.familiaips.com/api/web/v1/recrm/how-many-boxes${params}`, {
        method: 'get',
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('token')
        },
    })
        .then((response) => {
            //console.log(response.json())
            return response.json()
        })
        .catch((error) => {
            console.log(error)
            return {error: 'true'}
        });
    return resp;
    
}

export const GetLogs = async (params, method) => {
    //const params = 
    if (!params.length)
        params = '';
    var resp = await fetch(`https://crm.familiaips.com/api/web/v1/recrm/get-logs${params}`, {
        method: 'get',
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('token')
        },
    })
        .then((response) => {
            //console.log(response.json())
            return response.json()
        })
        .catch((error) => {
            console.log(error)
            return {error: 'true'}
        });
    return resp;
    
}