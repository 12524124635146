import React, { useState, useEffect } from 'react';
import {FaBars, FaCircle, FaPhone, FaPhoneAlt, FaUser} from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';
import { Button } from '../../components/ui/Button';
import { Select } from '../ui/Select';
import { AssignDriver } from '../../api/Shipment';
import { GetDrivers } from '../../api/User';
import { useNavigate } from "react-router-dom";



export const DriverPicker = ({visible, setVisible, shipmentId, driver, userId}) => {

    const [driverId, setDriverId] = useState(0);
    const [drivers, setDrivers] = useState([]);
    const navigate = useNavigate();
    let location = useLocation();

    useEffect(() => {
        const drivers = async () => {
            var drv = Object.entries(await GetDrivers());
            drv.unshift(drv.pop());
            setDrivers(drv);
            //console.log(drv)
        }
        drivers();
    }, [])

    useEffect(() => {
        if (drivers.length){
            if (driver)
                setDriverId(driver)
            else
                setDriverId(drivers[1][0])
        }
            
    }, [drivers])

    useEffect(() => {
        if (drivers.length){
            if (driver !== 0)
                setDriverId(driver)
            else
                setDriverId(drivers[1][0])
        }
    }, [driver])

    const assignDriver = async () => {
        const res = await AssignDriver(driverId, shipmentId, userId);
        if (res)
            if (res.status === true){
                setVisible(false);
                navigate(location.pathname + location.search);
            } 
    }

    if (visible == false)
        return null;

    return (
        <div onClick={() => setVisible(false)} className='modal'>
            <div onClick={(e) => {e.stopPropagation()}} className='modal-container-driver-picker'>
                <div className='driver-picker'>
                    <div className='title'>Assign driver</div>
                    <hr />
                    <Select value={driverId ? driverId : ''} onChange={setDriverId} data={drivers.length ? drivers : ''} placeholderFirst={true}/>
                    <Button onClick={assignDriver} className='btn bg-green'>
                        Assign
                    </Button>
                    <hr />
                    <Button onClick={() => setVisible(false)} className='btn bg-grey ml-auto flex'>
                        Close
                    </Button>
                </div>
            </div>
        </div>
    );
}