import React from 'react';

export const InputWithLabel = ({onClick, className, onChange, value, placeholder, label, id, type, name, onBlur, onKeyUp, param, req, readonly}) => {
    return (
        <div className={className ? className : 'input-container'}>
            {
                label &&
                    <label htmlFor={id}>{label}</label>
            }
            <input
                name={name}
                type={type ? type : 'text'}
                value={value}
                onChange={e => {onChange(e.target.value)}}
                onClick={onClick}
                placeholder={placeholder}
                id={id}
                onBlur={() => {onBlur && onBlur('', param)}}
                onKeyUp={e => {onKeyUp && onKeyUp(e.key, param)}}
                required={req}
                readOnly={readonly}
            />
        </div>
    );
}