import React, { useEffect, useRef, useState } from 'react';
import { UserAgent, Registerer, Inviter } from 'sip.js'
import './Caller.css'

export const Caller = () => {
    //Initialization state manager
    const [status, setStatus] = useState('Disconnected')
    const [callStatus, setCallStatus] = useState('Established')
    const [phone, setPhone] = useState('')
    const [phoneUser, setPhoneUser] = useState('')
    const [inviter, setInviter] = useState(null)
    const [time, setTime] = useState(false)
    const [mutePhone, setMutePhone] = useState(false)
    const [modalForward, setModalForward] = useState(false)
    const [modalAccept, setModalAccept] = useState(false)
    const [forwardNumber, setForwardNumber] = useState('')
    const [name, setName] = useState('')
  
    //Initialization variables
    const audioElement = useRef()
    const userAgentOptions = useRef({
      logBuiltinEnabled: true,
      displayName: name,
      authorizationPassword: 'Qweasd123!!',
      authorizationUsername: '',
      transportOptions: {
        server: 'wss://pbx.familiaips.com:8089/ws',
      },
      delegate: {
        onInvite: invitation => {
          setModalAccept(true)
          setInviter(invitation)
          invitation.stateChange.addListener(state => {
            setCallStatus(state)
            if (state === 'Established') {
              const remoteStream = new MediaStream()
              invitation.sessionDescriptionHandler.peerConnection.getReceivers().forEach(receiver => {
                if (receiver.track) {
                  remoteStream.addTrack(receiver.track)
                }
              })
              audioElement.current.srcObject = remoteStream
              audioElement.current.play()
              setTime(true)
            }
            if (state === 'Terminated') {
              audioElement.current.srcObject = null
              audioElement.current.pause()
              setTime(false)
              setModalAccept(false)
            }
          })
        },
        onDisconnect: error => {
          setStatus('Disconnect')
          console.log(error)
        },
        onConnect: () => {
          setStatus('Connected')
        },
      },
      uri: UserAgent.makeURI('sip:7028@pbx.familiaips.com:5060'),
    })
    const userAgent = useRef(new UserAgent(userAgentOptions.current))
    const registerer = useRef(new Registerer(userAgent.current))
  
    //Start sip client
    useEffect(() => {
      userAgent.current.start().then(() => {
        registerer.current.register()
      })
    }, [])
  
    //Function for call to user
    const makeCall = () => {
      userAgent.current.start().then(() => {
        const target = UserAgent.makeURI(`sip:${phone}@pbx.familiaips.com:5060`)
        const inviter = new Inviter(userAgent.current, target)
        inviter.invite()
        inviter.stateChange.addListener(state => {
          setPhoneUser(target.normal.user)
          setCallStatus(state)
          if (state === 'Establishing') {
            setInviter(inviter)
          }
          if (state === 'Established') {
            const remoteStream = new MediaStream()
            inviter.sessionDescriptionHandler.peerConnection.getReceivers().forEach(receiver => {
              if (receiver.track) {
                remoteStream.addTrack(receiver.track)
              }
            })
            audioElement.current.srcObject = remoteStream
            audioElement.current.play()
            setTime(true)
          }
          if (state === 'Terminated') {
            audioElement.current.srcObject = null
            audioElement.current.pause()
            setCallStatus('')
            setPhoneUser('')
            setTime(false)
          }
        })
        setPhone('')
      })
    }
  
        <div className='recruitingModalDelete'>
          <div className='header-modal'>
            <h2>Forward call</h2>
            <div
            
              onClick={() => {
                setForwardNumber('')
                setModalForward(false)
              }}
            >
              close
             </div>
          </div>
          <div>
            <input type='text' placeholder='Your name: 7028' value={name} onChange={event => setName(event.target.value)} />
            <input type='text' placeholder='Example: 7027' value={forwardNumber} onChange={event => setForwardNumber(event.target.value)} />
            <div className='forward-buttons'>
              <button
                onClick={() => {
                  const target = UserAgent.makeURI(`sip:${forwardNumber}@pbx.familiaips.com:5060`)
                  inviter.refer(target)
                  inviter.bye()
                  setModalForward(false)
                  setForwardNumber('')
                }}
              >
                {('Forward')}
              </button>
              <button
                onClick={() => {
                  setModalForward(false)
                  setForwardNumber('')
                }}
              >
                {('Close')}
              </button>
            </div>
          </div>
        </div>
  
    const acceptModal = modalAccept ? (
      <div className='acceptModal'>
        <div className='accept-main'>
          <div className='header-modal'>
            <h2>{('Accept call')}</h2>
          </div>
          <div className='accept-buttons'>
            <button
              onClick={() => {
                inviter.accept()
                setModalAccept(false)
              }}
            >
              {('Accept')}
            </button>
            <button
              onClick={() => {
                inviter.reject()
                setModalAccept(false)
              }}
            >
              {'Reject'}
            </button>
          </div>
        </div>
      </div>
    ) : null
  
    return (
      <section className='callcenter'>
        <div className='callcenter-main'>
          <div className='phone'>
            <h3 className='status'>
              Status: {status} <span className={status === 'Connected' ? 'status-indicator connected' : 'status-indicator'}></span>
            </h3>
            <div className='phone-form'>
              <input type='text' placeholder='Your name: 7028' value={name} onChange={event => setName(event.target.value)} />
              <input type='number' value={phone} onChange={event => setPhone(event.target.value)} placeholder='Example: 7027' />
              <button className='btn' onClick={makeCall}>Call</button>
            </div>
            {callStatus === 'Establishing' || callStatus === 'Established' ? (
            <div className='dialog-status'>
              <h3 className='status'>Status: {callStatus}</h3>
              {callStatus === 'Established' ? (
                <>
                  <b>User: {phoneUser}</b>
                </>
              ) : null}
              <div className='phone-buttons'>
                {callStatus === 'Established' ? (
                  <>
                    <button className='forward btn' onClick={() => setModalForward(true)}>
                      call forward
                    </button>
                    <button
                      className={mutePhone ? 'forward unmute btn' : 'forward btn'}
                      onClick={() => {
                        setMutePhone(!mutePhone)
                        inviter.sessionDescriptionHandler.peerConnection.getLocalStreams().forEach(function (stream) {
                          stream.getAudioTracks().forEach(function (track) {
                            track.enabled = mutePhone
                          })
                        })
                      }}
                    >
                      mute
                    </button>
                    <button onClick={() => inviter.bye()} className='end btn'>
                      call end
                    </button>
                  </>
                ) : null}
                {callStatus === 'Establishing' ? (
                  <button className='end btn' onClick={() => inviter.cancel()}>
                    call end
                  </button>
                ) : null}
              </div>
            </div>
            ) : null}
            <audio id='remoteAudio' controls ref={audioElement}>
              <p>Your browser doesn't support HTML5 audio.</p>
            </audio>
          </div>
          
          
        </div>
        
        {acceptModal}
      </section>
    )
  }