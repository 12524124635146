import React, { forwardRef, useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { GetClients } from '../../api/Client';
import { Button } from '../../components/ui/Button';
import { Pagination } from '../../components/ui/Pagination';
import { DoCallContext, LoadingContext, UserContext } from '../../context';
import {FaIdCard, FaShieldAlt, FaMapMarkerAlt, FaEye, FaPencilAlt, FaTrash, FaCalendarAlt, FaFileSignature} from 'react-icons/fa';
import { Input } from '../../components/ui/Input';
import { InputWithLabel } from '../../components/ui/InputWithLabel';
import { GetCallLog } from '../../api/Call';
import {FiPhoneIncoming, FiPhoneMissed, FiPhoneOutgoing} from 'react-icons/fi';
import { GetLogs } from '../../api/Analitics';
import { ADMIN } from '../../const/const';
import { ReportShipment } from '../../api/Report';
import { compareAsc, format } from 'date-fns'
import { InputLabelAndChildren } from '../../components/ui/InputLabelAndChildren';
import DatePicker from "react-datepicker";
import { Select } from '../../components/ui/Select';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

export const RepShipments = () => {
    const [data, setData] = useState([]);
    const [drivers, setDrivers] = useState([]);
    const [dateStart, setDateStart] = useState(new Date());
    const [dateEnd, setDateEnd] = useState(new Date());
    const [driver, setDriver] = useState(0);
    const {setLoading} = useContext(LoadingContext);
    const {user} = useContext(UserContext);
    const {doCall, setDoCall} = useContext(DoCallContext);
    let location = useLocation();
    const navigate = useNavigate();

    const CustomButtonDate = forwardRef(({ onClick }, ref) => (
        <button type='button' className='btn-calendar' onClick={onClick} ref={ref}>
            <FaCalendarAlt color='#555'/>
        </button>
    ));

    const reasons = new Map([
        ['1', 'Used the services of another company'],
        ['2', 'The client changed his mind'],
        ['3', 'The client did not have time today'],
        ['4', 'The driver didn\'t have time'],
        ['5', 'Other'],
    ]);

    useEffect(() => {
        getShipments();
    }, [user, location])

    const getShipments = async() => {
        setLoading(true);
        if (user){
            const resp = await ReportShipment(location.search);
            console.log(resp);
            setData(resp);
            var arr = [[0, 'all']];
            resp?.drivers.map((item) => {
                arr.push([item.id, item.userProfile.firstname + ' ' + item.userProfile.lastname])
            })
            setDrivers(arr);
            /* if (resp){
                resp.logs?.map((item, index) => {
                    item.before_changes = (item.before_changes);
                })
                console.log(resp);
            } */
            setLoading(false);
        }  
    }

    const paramsChanger = () => {
        var params = new URLSearchParams(location.search);
        if (params){
            params.delete('page');
            //format(dateEnd, 'yyyy-MM-dd')
            params.set('date_start', format(dateStart, 'yyyy-MM-dd'));
            params.set('date_end', format(dateEnd, 'yyyy-MM-dd'));
            if (driver !== 0)
                params.set('driver', driver);
            navigate('/report/shipment?' + params);
        }
    }

    const paramChangerBtn = () => {
        /* var post = new FormData();
        post.append('filterParams[dateRangeStart]', format(dateStart, 'yyyy-MM-dd'));
        post.append('filterParams[dateRangeEnd]', format(dateEnd, 'yyyy-MM-dd'));
        if (driver !== 0)
            post.append('filterParams[drivers][]', driver);
        var params = new URLSearchParams(location.search);
        params.delete('page');
        getShipments(post); */
        paramsChanger();
    }

    const findByParam = (key, param) => {
        if (key.length){
            if (key === 'Enter'){
                paramsChanger(param);
            }
        }
        else{
            paramsChanger(param);
        }
    }

    /* if (user.role !== ADMIN){
        return(
            <>
                Not enough rights to view
            </>
        )
    } */
    const statusMaker = (status) => {
        switch (status){
            case '1':
                return 'New';
            case '2':
                return 'Assigned';
            case '3':
                return 'Started';
            case '4':
                return 'Picked Up';
            case '5':
                return 'At Warehouse';
            case '6':
                return 'Reassign';
            case '7':
                return 'Canceled';
            default:
                return 'New';
        }
    }

    const downloadPoa = async() => {
        var post = {
            poa: 1
        }
        const resp = await ReportShipment(location.search, post, 'post');
        console.log(resp);
        if (resp !== 0 && resp !== -1)
            window.open('https://' + resp, '_blank', 'noreferrer');
        else
            alert(`There are no PoA's`);
    }
    const downloadPassport = async() => {
        var post = {
            passport: 1
        }
        const resp = await ReportShipment(location.search, post, 'post');
        console.log(resp);
        if (resp !== 0 && resp !== -1)
            window.open('https://' + resp, '_blank', 'noreferrer');
        else
            alert(`There are no passports`);
    }
    const packageTypeMaker = (item) => {
        var text = '';
        item.packagesWithAddress?.forEach((pack, i) => {
            if (i !== 0)
                text += ' | '
            switch (pack.package_type + pack.empty){
                case 'BIG0':
                    text += '1p';
                    break;
                case 'BIG1':
                    text += '1g';
                    break;
                case 'SMALL0':
                    text += '1mp';
                    break;
                case 'SMALL1':
                    text += '1mg';
                    break;
            }
        })
        return text;
    }
    const packageDestinationMaker = (item) => {
        var text = '';
        item.packagesWithAddress?.forEach((pack, i) => {
            if (i !== 0)
                text += ' | ';
            text += pack.deliveryAddress?.country;
        })
        return text;
    }
    const exportToExcel = async() => {
        if (data.all){
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            const fileExtension = '.xlsx';
            var csvData = [];
            var text1;
            data.all.forEach((item, index) => {
                text1 = '';
                item.packagesWithAddress?.map((pack, i) => {
                    text1 += (i !== 0 ? ', ' : '') + (pack.code) + (' - ') + (pack.package_type) + (pack.empty === '1' ? ' - EMPTY' : '') + (pack.gift === '1' ? ' - GIFT' : '');
                })
                var thing = {
                    'Pickup date': item.pickup_date,
                    'Pickup city': item.pickupAddress?.city,
                    'Pickup address': item.pickupAddress?.street + ', ' + item.pickupAddress?.house,
                    'Contact name': item.shipperInfoAttachs?.contact_name,
                    'Phone number': item.shipperInfoAttachs?.phone_number,
                    'Boxes': text1,
                    'Boxes type': packageTypeMaker(item),
                    'Destination': packageDestinationMaker(item),
                    'PoA': item.shipperInfoAttachs.attorney ? (item.shipperInfoAttachs.attorney.status === '1' ? 'printed' : 'verified') : 'unverified',
                    'Description': item.description,
                    'Driver': item.driverFine?.firstname + ' ' + item.driverFine.lastname,
                    'Status': statusMaker(item.status),
                    'Canceled by': item.canceled ? item.canceled.manager.firstname + ' ' + item.canceled.manager.lastname : '',
                    'Cancel reason': item.canceled ? reasons.get(item.canceled.reason) + ' ' + item.canceled.comment : '',
                }
                csvData.push(thing);
            })
            const fileName = 'test';
            const ws = XLSX.utils.json_to_sheet(csvData);
            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data1 = new Blob([excelBuffer], {type: fileType});
            FileSaver.saveAs(data1, fileName + fileExtension);
        }
    }

    return (
        <div className='default-template'>
            <div className="dt-title">Report Shipments</div>
            <div className="dt-body">
                <div className='filter-btns-report'>
                    <div>
                        <InputLabelAndChildren value={format(dateStart, 'yyyy-MM-dd')} onChange={setDateStart} label='Date start' disabled={true}>
                            <DatePicker
                                selected={dateStart}
                                onChange={setDateStart}
                                customInput={<CustomButtonDate/>}
                                closeOnScroll={true}
                                dateFormat="MM/dd/yyyy h:mm aa"
                            />
                        </InputLabelAndChildren>
                        <InputLabelAndChildren value={format(dateEnd, 'yyyy-MM-dd')} onChange={setDateEnd} label='Date end' disabled={true}>
                            <DatePicker
                                selected={dateEnd}
                                onChange={setDateEnd}
                                customInput={<CustomButtonDate/>}
                                closeOnScroll={true}
                                dateFormat="MM/dd/yyyy h:mm aa"
                            />
                        </InputLabelAndChildren>
                        <Select value={driver ? driver : ''} onChange={setDriver} data={drivers.length ? drivers : ''} label='Driver' placeholderFirst={false}/>
                    </div>
                    <Button className='btn bg-green mr-5' onClick={paramChangerBtn}>Filter</Button>
                    <Button className='btn mr-5' onClick={downloadPoa}>Download Power of Attorneys</Button>
                    <Button className='btn mr-5' onClick={downloadPassport}>Download passports</Button>
                    <Button onClick={exportToExcel}>Export to Excel</Button>
                </div>
                <div className="count-of-elements">
                    Found <span>{data ? data.count : '???'}</span> items.
                </div>
                <div className='table-default'>
                    <table className='table-clients'>
                        <thead>
                            <tr>
                                <th>Pickup date</th>
                                <th>Pickup city</th>
                                <th>Pickup address</th>
                                <th>Contact name</th>
                                <th>Phone number</th>
                                <th>Boxes</th>
                                <th>Boxes type</th>
                                <th>Destination</th>
                                <th>PoA</th>
                                <th>Description</th>
                                <th>Driver</th>
                                <th>Status</th>
                                <th>Canceled by</th>
                                <th>Cancel reason</th>
                            </tr>
                        
                        </thead>
                        <tbody>
                            {
                                data?.shipments?.map((item, index) => {
                                    return(
                                        <tr key={index}>
                                            <td>{item.pickup_date}</td>
                                            <td>{item.pickupAddress?.city}</td>
                                            <td>{item.pickupAddress?.street + ', ' + item.pickupAddress?.house}</td>
                                            <td>{item.shipperInfoAttachs?.contact_name}</td>
                                            <td>{item.shipperInfoAttachs?.phone_number}</td>
                                            <td>{item.packagesWithAddress?.map((pack, i) => {
                                                return <React.Fragment key={i}>{pack.code + ' - ' + pack.package_type + (pack.empty === '1' ? ' - EMPTY' : '') + (pack.gift === '1' ? ' - GIFT' : '')}<br/></React.Fragment>
                                            })}</td>
                                            <td>{
                                                packageTypeMaker(item)
                                            }</td>
                                            <td>{
                                                packageDestinationMaker(item)
                                            }</td>
                                            <td><FaFileSignature size={20} color={item.shipperInfoAttachs.attorney ? (item.shipperInfoAttachs.attorney.status === '1' ? '#f1c40f' : 'green') : 'red'} /></td>
                                            <td>{item.description}</td>
                                            <td>{item.driverFine?.firstname + ' ' + item.driverFine.lastname}</td>
                                            <td>{statusMaker(item.status)}</td>
                                            <td>
                                                {
                                                    item.canceled ?
                                                        item.canceled.manager.firstname + ' ' + item.canceled.manager.lastname
                                                        :
                                                        <></>
                                                } 
                                            </td>
                                            <td>
                                                {
                                                    item.canceled ?
                                                        <>
                                                            {reasons.get(item.canceled.reason)} <br />
                                                            {item.canceled.comment}
                                                        </>
                                                        :
                                                        <></>
                                                }        
                                            </td>
                                        </tr>
                                    )
                                    
                                })
                            }
                        </tbody>
                    </table>
                </div>
                <Pagination totalCount={data && data.count > 0 && data.count} refresher={location}/>
            </div>
        </div>
    );
}