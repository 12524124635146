import React from 'react';

export const InputRadioBox = ({onClick, disabled, checked, className, inputClassName, onChange, value, placeholder, children, id, type, name, onBlur, onKeyUp, param}) => {
    return (
        <label className={className ? className : 'input-container'}>
            <input
                name={name}
                disabled={disabled}
                type={type ? type : 'radio'}
                value={value}
                onChange={e => {e.target.type === 'checkbox' ? onChange(e.target.checked) : onChange(e.target.value)}}
                onClick={onClick}
                placeholder={placeholder}
                id={id}
                onBlur={() => {onBlur && onBlur('', param)}}
                onKeyUp={e => {onKeyUp && onKeyUp(e.key, param)}}
                className={inputClassName}
                checked={checked}
            />
            {children}
        </label>
    );
}