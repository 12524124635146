import { GetClientByPhone } from "../api/Client";

export const clearRegionNumber = (number) => {
    const regex1 = RegExp('972');
    const regex2 = /\+972/;
    var numb = number;
    if (regex2.exec(numb) !== null)
      numb = numb.replace(regex2, '0')
    
    if (regex1.exec(numb) !== null && numb.indexOf('972') === 0)
      numb = numb.replace(regex1, '0')
      
    return numb;
}

export const findClient = async (phoneUser) => {
    const result = await GetClientByPhone(phoneUser)
    //console.log(result)
    if (result !== 0)
        return result
    else
        return {}
}