import React, { useContext, useState } from 'react';
import {FaBars, FaBook, FaDatabase, FaFileInvoice, FaIdCard, FaTruckMoving, FaUser} from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { ADMIN } from '../../const/const';
import { UserContext } from '../../context';
import { IoAnalytics, IoMdAnalytics } from "react-icons/io";
import { SidebarItem } from '../../components/ui/SidebarItem';
import { SidebarAccordion } from '../../components/ui/SidebarAccordion';

export const Sidebar = ({hideSidebarMenu}) => {
    const [isSidebarOpen, setSidebarOpen] = useState(true);
    const [dropdowns, setDropdowns] = useState({
        ['analytics']: false
    });
    const {user} = useContext(UserContext);

    const openDropdown = (item, status) => {
        const drop = dropdowns;
        drop[item] = status;
        setDropdowns({...drop});
    }

    return (
        <div className="sidebar">
            <ul>
                <SidebarItem><Link to='/shipments'><FaTruckMoving className='mr-5' color='white' size={16}/>Shipments</Link></SidebarItem>
                <SidebarItem><Link to='/clients'><FaIdCard className='mr-5' color='white' size={16}/>Clients</Link></SidebarItem>
                <SidebarItem><Link to='/call-log'><FaBook className='mr-5' color='white' size={16}/>Call Log</Link></SidebarItem>
                <SidebarItem><Link to='/report/shipment'><FaFileInvoice className='mr-5' color='white' size={16}/>Report Shipments</Link></SidebarItem>
                
                <SidebarAccordion
                    opened={dropdowns.analytics}
                    callback={openDropdown}
                    text='Analytics'
                    icon={<IoMdAnalytics className='mr-5' color='white' size={16}/>}
                >
                    <SidebarItem><Link to='/analitics/created-shipments'><FaFileInvoice className='mr-5' color='white' size={16}/>Created shipments</Link></SidebarItem>
                    <SidebarItem role={ADMIN}><Link to='/analitics/sent-poa-htm'><FaFileInvoice className='mr-5' color='white' size={16}/>Sent PoA (htm)</Link></SidebarItem>
                    <SidebarItem role={ADMIN}><Link to='/analitics/empty-boxes'><FaFileInvoice className='mr-5' color='white' size={16}/>Empty Packages</Link></SidebarItem>
                    {/* <SidebarItem role={ADMIN}><Link to='/analitics/how-many-boxes'><FaFileInvoice className='mr-5' color='white' size={16}/>Boxes</Link></SidebarItem> */}
                </SidebarAccordion>
                
                <SidebarItem role={ADMIN}><Link to='/action-log'><FaDatabase className='mr-5' color='white' size={16}/>Log</Link></SidebarItem>
            </ul>
        </div>
    );
}