export const ReportShipment = async (params, data, method) => {
    //const params = 
    if (!params.length)
        params = '';
    var resp = await fetch(`https://crm.familiaips.com/api/web/v1/recrm/get-report-shipment${params}`, {
        method: method ? method : 'get',
        headers: {
            "Authorization": "Bearer " + localStorage.getItem('token')
        },
        [method === 'post' ? 'body' : '']: JSON.stringify(data)
    })
        .then((response) => {
            //console.log(response.json())
            return response.json()
        })
        .catch((error) => {
            console.log(error)
            return {error: 'true'}
        });
    return resp;
    
}