import React from 'react';

export const Select = ({onClick, className, onChange, value, placeholder, label, id, type, data, name, 
    selected, placeholderFirst, onClickContainer}) => {
    //console.log(data)
    // selected={selected == item[0] && true}
    return (
        
        <div onClick={onClickContainer} className={className ? className : 'select-container'}>
            {
                label &&
                    <label htmlFor={id}>{label}</label>
            }
            <select value={value} onChange={e => {/* console.log(e.target.value); */ onChange(e.target.value)}} name={name} id={id} defaultValue={selected}>
                {
                    data && 
                        data.map((item, index) => {
                            return <option disabled={index === 0 && placeholderFirst && placeholderFirst}
                            key={index}
                            value={item[0]}>{item[1]}</option>
                        })
                }
            </select>
        </div>
    );
}