export const Auth = async (login, password) => {
    /* data.append('login', login);
    data.append('password', password); */
    /* let data = JSON.stringify({
        "email": "macovei_iaroslav@agg.md",
	    "password": "5698"
    }); */
    let data = JSON.stringify({
        "username": login,
	    "password": password
    });
    var resp = await fetch('https://crm.familiaips.com/api/web/v1/auth/re-crm', {
        method: 'post',
        headers: {
            "Content-Type": "application/json",
        },
        body: data
    })
        .then((response) => {
            //console.log(response.json())
            return response.json()
        })
        .catch((error) => {
            console.log(error)
            return {error: 'true'}
        });
    return resp;
    
}
export const GetMainAccountInfo = async () => {
    const data = {
        "token": localStorage.getItem('token')
    }
    console.log(localStorage.getItem('token'))
    var resp = await fetch('https://crm.familiaips.com/api/web/v1/recrm/get-main-account-info', {
        method: 'post',
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + data.token
        },
        body: JSON.stringify(data)
    })
        .then((response) => {
            //console.log(response.json())
            return response.json()
        })
        .catch((error) => {
            console.log(error)
            return {error: 'true'}
        });
    return resp;
    
}
export const GetProfile = async (id) => {
    const data = {
        "id": id
    }
    console.log(localStorage.getItem('token'))
    var resp = await fetch('https://crm.familiaips.com/api/web/v1/recrm/get-profile', {
        method: 'post',
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    })
        .then((response) => {
            //console.log(response.json())
            return response.json()
        })
        .catch((error) => {
            console.log(error)
            return {error: 'true'}
        });
    return resp;
    
}

export const GetDrivers = async () => {
    console.log(localStorage.getItem('token'))
    var resp = await fetch('https://crm.familiaips.com/api/web/v1/recrm/get-drivers', {
        method: 'get',
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('token')
        },
    })
        .then((response) => {
            //console.log(response.json())
            return response.json()
        })
        .catch((error) => {
            console.log(error)
            return {error: 'true'}
        });
    return resp;
    
}