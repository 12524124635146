import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { GetClients } from '../../api/Client';
import { Button } from '../../components/ui/Button';
import { Pagination } from '../../components/ui/Pagination';
import { DoCallContext, LoadingContext, UserContext } from '../../context';
import {FaIdCard, FaShieldAlt, FaMapMarkerAlt, FaEye, FaPencilAlt, FaTrash} from 'react-icons/fa';
import { Input } from '../../components/ui/Input';
import { InputWithLabel } from '../../components/ui/InputWithLabel';
import { GetCallLog } from '../../api/Call';
import {FiPhoneIncoming, FiPhoneMissed, FiPhoneOutgoing} from 'react-icons/fi';
import { GetLogs } from '../../api/Analitics';
import { ADMIN } from '../../const/const';

export const ActionLog = () => {
    const [data, setData] = useState([]);
    const [modelId, setModelId] = useState('');
    const [manager, setManager] = useState('');
    const [date, setDate] = useState('');
    const [time, setTime] = useState('');
    const [model, setModel] = useState('');
    const [action, setAction] = useState('');
    const [beforeChanges, setBeforeChanges] = useState('');
    const [afterChanges, setAfterChanges] = useState('');
    const {setLoading} = useContext(LoadingContext);
    const {user} = useContext(UserContext);
    const {doCall, setDoCall} = useContext(DoCallContext);
    let location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        getLogs();
    }, [user, location])

    const getLogs = async() => {
        setLoading(true);
        if (user){
            const resp = await GetLogs(location.search);
            console.log(resp);
            setData(resp);
            /* if (resp){
                resp.logs?.map((item, index) => {
                    item.before_changes = (item.before_changes);
                })
                console.log(resp);
            } */
            setLoading(false);
        }  
    }

    const paramsChanger = (param) => {
        var params = new URLSearchParams(location.search);
        if (params){
            params.delete('page');
            switch (param){
                case 'model_id':
                    params.set(param, modelId);
                    break;
                case 'manager':
                    params.set(param, manager);
                    break;
                case 'date':
                    params.set(param, date);
                    break;
                case 'time':
                    params.set(param, time);
                    break;
                case 'model':
                    params.set(param, model);
                    break;
                case 'action':
                    params.set(param, action);
                    break;
                case 'before_changes':
                    params.set(param, beforeChanges);
                    break;
                case 'after_changes':
                    params.set(param, afterChanges);
                    break;
            }
            navigate('/action-log?' + params);
        }
    }

    const findByParam = (key, param) => {
        if (key.length){
            if (key == 'Enter'){
                paramsChanger(param);
            }
        }
        else{
            paramsChanger(param);
        }
    }

    if (user.role !== ADMIN){
        return(
            <>
                Not enough rights to view
            </>
        )
    }

    return (
        <div className='default-template'>
            <div className="dt-title">Action Log</div>
            <div className="dt-body">
                <div className="count-of-elements">
                    Found <span>{data ? data.count : '???'}</span> items.
                </div>
                <div className='table-default'>
                    <table className='table-clients'>
                        <thead>
                            <tr>
                                <th>Model ID</th>
                                <th>Manager</th>
                                <th>Date</th>
                                <th>Time</th>
                                <th>Model</th>
                                <th>Action</th>
                                <th>Before Changes</th>
                                <th>After Changes</th>
                            </tr>
                            <tr>
                                <td>
                                    <InputWithLabel
                                        className='input-container mb-0 w-100'
                                        value={modelId}
                                        onChange={setModelId}
                                        onBlur={findByParam}
                                        onKeyUp={findByParam}
                                        param='model_id'
                                    />
                                </td>
                                <td>
                                    <InputWithLabel
                                        className='input-container mb-0 w-100'
                                        value={manager}
                                        onChange={setManager}
                                        onBlur={findByParam}
                                        onKeyUp={findByParam}
                                        param='manager'
                                    />
                                </td>
                                <td>
                                    <InputWithLabel
                                        className='input-container mb-0 w-100'
                                        value={date}
                                        onChange={setDate}
                                        onBlur={findByParam}
                                        onKeyUp={findByParam}
                                        param='date'
                                    />
                                </td>
                                <td>
                                    <InputWithLabel
                                        className='input-container mb-0 w-100'
                                        value={time}
                                        onChange={setTime}
                                        onBlur={findByParam}
                                        onKeyUp={findByParam}
                                        param='time'
                                    />
                                </td>
                                <td>
                                    <InputWithLabel
                                        className='input-container mb-0 w-100'
                                        value={model}
                                        onChange={setModel}
                                        onBlur={findByParam}
                                        onKeyUp={findByParam}
                                        param='model'
                                    />
                                </td>
                                <td>
                                    <InputWithLabel
                                        className='input-container mb-0 w-100'
                                        value={action}
                                        onChange={setAction}
                                        onBlur={findByParam}
                                        onKeyUp={findByParam}
                                        param='action'
                                    />
                                </td>
                                
                                <td>
                                    <InputWithLabel
                                        className='input-container mb-0 w-100'
                                        value={beforeChanges}
                                        onChange={setBeforeChanges}
                                        onBlur={findByParam}
                                        onKeyUp={findByParam}
                                        param='before_changes'
                                    />
                                </td>
                                <td>
                                    <InputWithLabel
                                        className='input-container mb-0 w-100'
                                        value={afterChanges}
                                        onChange={setAfterChanges}
                                        onBlur={findByParam}
                                        onKeyUp={findByParam}
                                        param='after_changes'
                                    />
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data?.logs?.map((item, index) => {
                                    return(
                                        <tr key={index}>
                                            <td>{item.model_id}</td>
                                            <td>{item.user.username}</td>
                                            <td>{item.date}</td>
                                            <td>{item.time}</td>
                                            <td>{item.model}</td>
                                            <td>{item.action}</td>
                                            <td>
                                                {
                                                    Object.entries(item.before_changes).map((item, index) => {
                                                        return <React.Fragment key={index}>{`${item[0]} => ${item[1]}`} <br /></React.Fragment>
                                                    })
                                                }
                                            </td>
                                            <td>
                                                {
                                                    Object.entries(item.after_changes).map((item, index) => {
                                                        return <React.Fragment key={index}>{`${item[0]} => ${item[1]}`} <br /></React.Fragment>
                                                    })
                                                }
                                            </td>
                                        </tr>
                                    )
                                    
                                })
                            }
                        </tbody>
                    </table>
                </div>
                <Pagination totalCount={data && data.count > 0 && data.count} refresher={location}/>
            </div>
        </div>
    );
}