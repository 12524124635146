import React, { useState, useEffect } from 'react';
import {FaBars, FaCircle, FaDigitalTachograph, FaPhone, FaPhoneAlt, FaUser} from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';
import { Button } from '../ui/Button';
import { Select } from '../ui/Select';
import { AssignDriver } from '../../api/Shipment';
import { GetDrivers } from '../../api/User';
import { useNavigate } from "react-router-dom";
import { CreateAddress, CreatingClient } from '../../api/Client';
import { InputWithLabel } from '../ui/InputWithLabel';
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { PlacesAutocomplete } from '../ui/PlacesAutocomplete';
import { InputRadioBox } from '../../components/ui/InputRadioBox';

export const Content = ({visible, setVisible, content, callback}) => {


    const [cont, setCont] = useState({
        'item-1-name[]': '',
        'item-1-qty': '',
        'item-2-name[]': '',
        'item-2-qty': '',
        'item-3-name[]': '',
        'item-3-qty': '',
        'item-4-name[]': '',
        'item-4-qty': '',
        'item-5-name[]': '',
        'item-5-qty': '',
        'item-6-name[]': '',
        'item-6-qty': '',
        'item-7-name[]': '',
        'item-7-qty': '',
        'item-8-name[]': '',
        'item-8-qty': '',
        'item-9-name[]': '',
        'item-9-qty': '',
    });

    useEffect(() => {
        if (content !== ''){}
            //setCont(content);
        else
            setCont({
                'item-1-name[]': '',
                'item-1-qty': '',
                'item-2-name[]': '',
                'item-2-qty': '',
                'item-3-name[]': '',
                'item-3-qty': '',
                'item-4-name[]': '',
                'item-4-qty': '',
                'item-5-name[]': '',
                'item-5-qty': '',
                'item-6-name[]': '',
                'item-6-qty': '',
                'item-7-name[]': '',
                'item-7-qty': '',
                'item-8-name[]': '',
                'item-8-qty': '',
                'item-9-name[]': '',
                'item-9-qty': '',
            });
    }, [visible])

    const saveContent = async(e) => {
        e.preventDefault();
    }

    const contentChanger = (checked, type, name) => {
        console.log(cont)
        if (checked === true){
            cont[name] += type;
        }

        console.log(cont[name]);
        setCont(
            {...cont}
        )
    }

    if (visible == false)
        return null;

    return (
        <div onClick={() => setVisible(false)} className='modal scrollable'>
            <div onClick={(e) => {e.stopPropagation()}} className='modal-container-create-address'>
                <div className='driver-picker'>
                    <form onSubmit={saveContent}>
                        <div className='title'>
                            Edit content
                        </div>
                        <div className='package-content-container'>
                            <div>
                                <div>
                                <InputRadioBox
                                    inputClassName='hidden'
                                    className={'default-card w-100' + (cont && cont['item-1-name[]'].indexOf('clothes') !== -1 ? ' active' : '')}
                                    type='checkbox'
                                    name=''
                                    value={cont && cont['item-1-name[]'].indexOf('clothes') !== -1}
                                    onChange={(checked) => contentChanger(checked, 'clothes', 'item-1-name[]')}
                                >
                                    clothes / одежда
                                </InputRadioBox>
                                </div>
                                <div></div>
                            </div>
                        </div>
                        
                        <Button type='submit' className='btn bg-green'>
                            Save
                        </Button>
                    </form>
                </div>
            </div>
        </div>
    );
}