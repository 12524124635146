import React, { useContext, useEffect, useState } from 'react';
import {Bars, FaCheck, FaFileSignature, FaIdCard, FaShieldAlt, FaTimes} from 'react-icons/fa';
import { GetClient, UpdatePoaSend, UpdatingClient } from '../../api/Client';
import { LoadingContext, UserContext } from '../../context';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button } from '../../components/ui/Button';
import { Select } from '../../components/ui/Select';
import { InputWithLabel } from '../../components/ui/InputWithLabel';
import { ClientVerifyModal } from '../../components/modal/ClientVerify';
import { DownloadFile } from '../../api/File';
import { EditAttorneyPdfModal } from '../../components/modal/EditAttorneyPdf';
import { EditAttorneyHtmModal } from '../../components/modal/EditAttorneyHtm';
import { InputRadioBox } from '../../components/ui/InputRadioBox';

export const UpdateClient = () => {
    const [data, setData] = useState([]);
    const [verifyModal, setVerifyModal] = useState(false);
    const [attorneyPdf, setAttorneyPdf] = useState(false);
    const [attorneyHtm, setAttorneyHtm] = useState(false);

    const {user} = useContext(UserContext);
    const {setLoading} = useContext(LoadingContext);
    let location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        getClient();
    }, [user])

    const getClient = async() => {
        setLoading(true);
        if (user){
            const resp = await UpdatingClient(location.search);
            console.log(resp);
            setData(resp);
            setLoading(false);
        }  
    }

    const updateMainInfo = async(e) => {
        setLoading(true);
        e.preventDefault();
        const newInfo = {
            client_id: data.id,
            lang: data.lang,
            contact_name: data.contact_name,
            passport_id: data.passport_id,
            phone_number: data.phone_number,
            comment: data.comment,
            user_id: user.id,
        }
        const resp = await UpdatingClient(newInfo, 'post');
        console.log(resp)
        if (resp === 1)
            navigate('/clients/update'+location.search)
        setLoading(false);
    }
    const download = async(link) => {
        await DownloadFile(link)
    }
    const updateSendPoa = async(status) => {
        const resp = await UpdatePoaSend(data.id, user.id, status === false ? 0 : 1)
        getClient();
    }
    return (
        <div className='default-template'>
            {
                !data?.id ?
                    <div className="got-error">{data}</div>
                :
                <>
                    <div className="dt-title">{data?.contact_name} - {data.phone_number ? data.phone_number : 'no phone number'}</div>
                    <div className="dt-body">
                        <div className='cols'>
                            <div className='upd-client-container'>
                                <div className='upd-client-verif'>
                                    <Button onClick={() => setVerifyModal(true)} className='verif-btn'>
                                        <span>Verified</span>
                                        <FaIdCard className='mr-5' size={40} color={data?.verified === '1' ? 'green' : 'red'} />
                                    </Button>
                                    <Button className='verif-btn no-pointer'>
                                        <span>Reliable</span>
                                        <FaShieldAlt className='mr-5' size={40} color={data?.reliable === '1' ? '#ccc' : 'red'} />
                                    </Button>
                                </div>
                                <div className='upd-client-main-info'>
                                    <div className='title'>Main Info</div>
                                    <form onSubmit={updateMainInfo}>
                                        <Select
                                            data={Object.entries({
                                                ''  : 'Select language',
                                                'ru': 'Russian',
                                                'ro': 'Romanian',
                                                'en': 'English',
                                                'he': 'Hebrew',
                                                'ka': 'Georgian',
                                            })}
                                            label='Preffered Language'
                                            placeholderFirst={true}
                                            value={data?.lang}
                                            onChange={(val) => {
                                                data.lang = val;
                                                setData({...data});
                                            }}
                                        />
                                        <InputWithLabel
                                            label='Contact Name'
                                            req={true}
                                            value={data?.contact_name}
                                            onChange={(val) => {
                                                data.contact_name = val;
                                                setData({...data});
                                            }}
                                        />
                                        <InputWithLabel
                                            label='Passport ID'
                                            value={data?.passport_id}
                                            onChange={(val) => {
                                                data.passport_id = val;
                                                setData({...data});
                                            }}
                                        />
                                        <InputWithLabel
                                            label='Phone Number'
                                            req={true}
                                            value={data?.phone_number}
                                            onChange={(val) => {
                                                data.phone_number = val;
                                                setData({...data});
                                            }}
                                        />
                                        <InputWithLabel
                                            label='Comment'
                                            value={data?.comment}
                                            onChange={(val) => {
                                                data.comment = val;
                                                setData({...data});
                                            }}
                                        />
                                        <Button type='submit' className='btn'>
                                            Update
                                        </Button>
                                    </form>
                                </div>
                                <div className='upd-client-poa-pdf'>
                                    <div className="title">
                                        Power of Attorney (.pdf)
                                        {
                                            data?.attorney?.status ?
                                                <FaFileSignature className='ml-5' size={17} color={data?.attorney?.status === '2' ? 'green' : data?.attorney?.status === '1' ? '#f1c40f' : 'red'} />
                                            :
                                            <FaFileSignature className='ml-5' size={17} color={'red'} />
                                        }
                                    </div>
                                    <InputWithLabel
                                        label='Date of termination'
                                        req={true}
                                        readonly={true}
                                        value={data?.attorney?.date}
                                        onChange={(val) => {
                                            /* data.attorney.date = val;
                                            setData({...data}); */
                                        }}
                                    />
                                    <InputWithLabel
                                        label='Reference number'
                                        req={true}
                                        readonly={true}
                                        value={data?.attorney?.ref_number}
                                        onChange={(val) => {
                                            /* data.attorney.ref_number = val;
                                            setData({...data}); */
                                        }}
                                    />
                                    <Button onClick={() => setAttorneyPdf(true)} className='btn mr-5'>
                                        Edit
                                    </Button>
                                    {
                                        data?.attorney &&
                                            data?.attorney.status !== '0' ?
                                                <a className='btn' download href={'https://crm.familiaips.com/storage/web/source/1/'+data.attorney.local_name} target="_blank" rel="noreferrer">
                                                    Download
                                                </a>
                                            :
                                                <></>
                                    }
                                    
                                </div>
                                <div className='upd-client-poa-htm'>
                                    <div className="title">
                                        Power of Attorney (.htm)
                                        {
                                            data?.poa !== '' ?
                                                <FaCheck className='ml-5' size={17} color={'green'} />
                                            :
                                                <FaTimes className='ml-5' size={17} color={'red'} />
                                        }
                                    </div>
                                    <div className='attorney-htm-action-container'>
                                        <Button onClick={() => setAttorneyHtm(true)} className='btn mr-5'>
                                            Edit
                                        </Button>
                                        {
                                            data?.poa !== '' ?
                                                <a className='btn mr-5' download={true} href={'https://crm.familiaips.com/storage/web/source/1/'+data.poa} target="_blank" rel="noreferrer">
                                                    Download
                                                </a>
                                            :
                                                <></>
                                        }
                                        {
                                            data?.poa ?
                                                <InputRadioBox
                                                    className={`default-card ${data.poa_send !== '0' ? 'active' : '' }`} type='checkbox' value='1'
                                                    name='one_plus_one' inputClassName='hidden'
                                                    checked={data.poa_send === '0' ? false : true}
                                                    onChange={e => updateSendPoa(e)}
                                                >
                                                    Sent to the broker?
                                                </InputRadioBox>
                                            :
                                                <></>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='upd-client-addresses'>
                                {
                                    data?.correctAddresses?.length > 0 ?
                                        data.correctAddresses.map((item) => {
                                            return(
                                                <div key={item.id} className="pickup-address-card no-pointer mh-160">
                                                    {item.country && <>{item.country} <br /></>}
                                                    {item.region && <>{item.region} <br /></>}
                                                    {item.city && <>{item.city} <br /></>}
                                                    {item.postal_code && <>{item.postal_code} <br /></>}
                                                    {item.street && item.street}
                                                    {item.house && <>, H.: {item.house}</>}
                                                    {item.apartment && <>, Ap.: {item.apartment}</>}
                                                </div>
                                            )
                                        })
                                    :
                                    <></>
                                }
                            </div>
                            
                        </div>
                        
                    </div>
                </>
                    
            }
            <ClientVerifyModal visible={verifyModal} setVisible={setVerifyModal} client={data} user={user} uploaded={getClient}/>
            <EditAttorneyPdfModal visible={attorneyPdf} setVisible={setAttorneyPdf} client={data} user={user} uploaded={getClient}/>
            <EditAttorneyHtmModal visible={attorneyHtm} setVisible={setAttorneyHtm} client={data} user={user} uploaded={getClient}/>
        </div>
    );
}