import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GetProfile } from '../../api/User';
import { Button } from '../../components/ui/Button';
import { InputWithLabel } from '../../components/ui/InputWithLabel';
import { Select } from '../../components/ui/Select';
import { LoadingContext, UserContext } from '../../context';

export const Profile = () => {
    const [firstname, setFirstname] = useState('');
    const [middlename, setMiddlename] = useState('');
    const [lastname, setLastname] = useState('');
    const [locales, setLocales] = useState({});
    const [profile, setProfile] = useState({});
    const {user, setUser} = useContext(UserContext);
    const {loading, setLoading} = useContext(LoadingContext);
    const navigate = useNavigate();
    const update = (e) => {
        e.preventDefault();
    }

    useEffect(() => {
        const getProfile = async() => {
            setLoading(true);
            if (user){
                const data = await GetProfile(user.id);
                console.log(data);
                if (data.user_profile){
                    if (data.user_profile.firstname)
                        setFirstname(data.user_profile.firstname);
                    if (data.user_profile.middlename)
                        setMiddlename(data.user_profile.middlename);
                    if (data.user_profile.lastname)
                        setLastname(data.user_profile.lastname);
                }
                setLocales(data.locales);
                setProfile(data.user_profile);
                setLoading(false);
            }  
        }
        getProfile();
    }, [user])

    const logout = () => {
        localStorage.removeItem('token');
        setUser(null);
        navigate('/login');
    }
    return (
        <div className='default-template'>
            <div className="dt-title">Edit profile</div>
            <div className="dt-body">
                <form onSubmit={update}>
                    <div className='add-photo'>
                        <label htmlFor=""></label>
                    </div>
                    <InputWithLabel
                        value={firstname}
                        onChange={setFirstname}
                        label='Firstname'
                    />
                    <InputWithLabel
                        value={middlename}
                        onChange={setMiddlename}
                        label='Middlename'
                    />
                    <InputWithLabel
                        value={lastname}
                        onChange={setLastname}
                        label='Lastname'
                    />
                    <Select
                        label='Locale'
                        data={Object.entries(locales)}
                        selected={profile.locale}
                    />
                    <Select
                        label='Gender'
                        data={Object.entries({
                            '0': 'Female',
                            '1': 'Male'
                        })}
                        selected={profile.gender}
                    />
                    <div>
                        <Button type={'submit'}>
                            Update
                        </Button>
                        <Button onClick={logout} className='btn ml-10' type={'button'}>
                            Logout
                        </Button>
                    </div>
                    

                </form>
                
            </div>
        </div>
    );
}