import React, { useEffect, useState } from 'react';
import {FaArrowLeft, FaArrowRight} from 'react-icons/fa';
import { Link } from 'react-router-dom';

export const Pagination = ({totalCount, pageSize, refresher}) => {
    const totalPageCount = Math.ceil(totalCount / (pageSize ? pageSize : 20));
    const [url, setUrl] = useState(new URL(window.location.href));
    const [search, setSearch] = useState(new URLSearchParams(url.search).delete('page'));
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState([]);
    const [pageBtns, setPageBtns] = useState([]);

    useEffect(() => {
        paginate()
    }, [refresher, totalCount])
    const paginate = () => {
        const totalPageCount = Math.ceil(totalCount / (pageSize ? pageSize : 20));
        setUrl(new URL(window.location.href));
        const newSearch = new URLSearchParams(new URL(window.location.href).search);
        newSearch.delete('page');
        var currentPage = 1;
        if (new URLSearchParams(new URL(window.location.href).search).has('page')){
            currentPage = parseInt(new URLSearchParams(new URL(window.location.href).search).get('page'));
            setPage(currentPage);
        }
        else
            setPage(1);
        
        if (!newSearch)
            setSearch('');
        else
            setSearch(newSearch);
        var pageTimes = 6;
        if (totalPageCount <= 6){
            pageTimes = totalPageCount;
        }
            
        var neededPages = [];
        for(let i = 1; i <= pageTimes; i++){
            var newPage = currentPage + i;
            if (newPage < totalPageCount)
                neededPages.push(newPage);
            newPage = currentPage - i;
            if (newPage > 1)
                neededPages.push(newPage);
            if (neededPages.length === 6)
                break;
        }
        if (neededPages.length > 0){
            if (currentPage !== 1 && currentPage !== totalPageCount)
                neededPages.push(currentPage);
            neededPages.sort((a, b) => {
                return a - b;
            })
        }
        setPageBtns(neededPages);
    }
    //pageBtns.length && pageBtns[0] === 6 && 'mr-5'

    return (
        <div className='pagination'>
            <Link className='prev-page'
                to={
                    url.pathname + (page !== 1 ? `?page=${page - 1}&` : `?page=${page}&`)  + search
                }
                onClick={(e) => {
                    if (page === 1)
                        e.preventDefault();
                    else
                        window.scrollTo(0, 0);
                }}
            >
                <FaArrowLeft />
            </Link>
            <Link
                onClick={(e) => {
                    if (page === 1)
                        e.preventDefault();
                    else
                        window.scrollTo(0, 0);
                }}
                to={url.pathname + '?page=1&' + search}
                className={[page === 1 ? 'active' : '' + ' ' + (pageBtns && pageBtns[0] >= 3 && 'mr-5')]}
            >
                1
            </Link>
            {
                pageBtns.length > 0 &&
                    pageBtns.map((item) => {
                        return(
                            <Link 
                                key={item}
                                to={url.pathname + `?page=${item}&` + search}
                                className={page === item ? 'active' : ''}
                                onClick={(e) => {
                                    window.scrollTo(0, 0);
                                }}
                            >
                                {item}
                            </Link>
                        )
                    })
            }
            {
                totalPageCount !== 1 ?
                    totalPageCount !== 0 ?
                        <Link
                            to={url.pathname + `?page=${totalPageCount && totalPageCount}&` + search}
                            className={[page === totalPageCount ? 'active' : '' + ' ' + (pageBtns && pageBtns[pageBtns.length - 1] <= totalPageCount - 2 && 'ml-5')]}
                            onClick={(e) => {
                                if (page === totalPageCount)
                                    e.preventDefault();
                                else
                                    window.scrollTo(0, 0);
                            }}
                        >
                            {totalPageCount && totalPageCount}
                        </Link>
                        :
                        <></>
                :
                <></>
            }
            
            <Link to={
                url.pathname + (page !== totalPageCount ? `?page=${page + 1}&` : `?page=${page}&`)  + search
                }
                className='next-page'
                onClick={(e) => {
                    if (page === totalPageCount || totalPageCount === 1)
                        e.preventDefault();
                    else
                        window.scrollTo(0, 0);
                }}
            >
                <FaArrowRight />
            </Link>
        </div>
    );
}