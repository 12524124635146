export const GetClients = async (params, method) => {
    //const params = 
    if (!params.length)
        params = '';
    var resp = await fetch(`https://crm.familiaips.com/api/web/v1/recrm/get-clients${params}`, {
        method: 'get',
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('token')
        },
    })
        .then((response) => {
            //console.log(response.json())
            return response.json()
        })
        .catch((error) => {
            console.log(error)
            return {error: 'true'}
        });
    return resp;
    
}
export const GetClient = async (params) => {
    //const params = 
    if (!params.length)
        params = '';
    var resp = await fetch(`https://crm.familiaips.com/api/web/v1/recrm/get-client${params}`, {
        method: 'get',
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('token')
        },
    })
        .then((response) => {
            //console.log(response.json())
            return response.json()
        })
        .catch((error) => {
            console.log(error)
            return {error: 'true'}
        });
    return resp;
    
}
export const CreatingClient = async (data) => {
    //const params = 
    var resp = await fetch(`https://crm.familiaips.com/api/web/v1/recrm/create-client`, {
        method: 'post',
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    })
        .then((response) => {
            //console.log(response.json())
            return response.json()
        })
        .catch((error) => {
            console.log(error)
            return {error: 'true'}
        });
    return resp;
    
}
export const GetClientByPhone = async (phone) => {
    const data = {
        phone: phone
    }
    var resp = await fetch(`https://crm.familiaips.com/api/web/v1/recrm/get-client-by-phone`, {
        method: 'post',
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    })
        .then((response) => {
            //console.log(response.json())
            return response.json()
        })
        .catch((error) => {
            console.log(error)
            return {error: 'true'}
        });
    return resp;
    
}
export const SearchClientByPhone = async (phone) => {
    const data = {
        phone: phone
    }
    var resp = await fetch(`https://crm.familiaips.com/api/web/v1/recrm/search-client-by-phone`, {
        method: 'post',
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    })
        .then((response) => {
            //console.log(response.json())
            return response.json()
        })
        .catch((error) => {
            console.log(error)
            return {error: 'true'}
        });
    return resp;
    
}
export const GetAddresses = async (id, type, packageItem) => {
    const data = {
        id: id,
        type: type,
        package: packageItem
    }
    var resp = await fetch(`https://crm.familiaips.com/api/web/v1/recrm/get-addresses`, {
        method: 'post',
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    })
        .then((response) => {
            //console.log(response.json())
            return response.json()
        })
        .catch((error) => {
            console.log(error)
            return {error: 'true'}
        });
    return resp;
    
}
export const GetContactPersons = async (id) => {
    const data = {
        id: id,
    }
    var resp = await fetch(`https://crm.familiaips.com/api/web/v1/recrm/get-contact-persons`, {
        method: 'post',
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    })
        .then((response) => {
            //console.log(response.json())
            return response.json()
        })
        .catch((error) => {
            console.log(error)
            return {error: 'true'}
        });
    return resp;
    
}
export const GetPromoOpo = async (id) => {
    const data = {
        id: id,
    }
    var resp = await fetch(`https://crm.familiaips.com/api/web/v1/recrm/promo-opo`, {
        method: 'post',
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    })
        .then((response) => {
            //console.log(response.json())
            return response.json()
        })
        .catch((error) => {
            console.log(error)
            return {error: 'true'}
        });
    return resp;
    
}
export const CreateAddress = async (data) => {
    var resp = await fetch(`https://crm.familiaips.com/api/web/v1/recrm/create-address`, {
        method: 'post',
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    })
        .then((response) => {
            //console.log(response.json())
            return response.json()
        })
        .catch((error) => {
            console.log(error)
            return {error: 'true'}
        });
    return resp;
    
}
export const CreateContactPerson = async (data) => {
    var resp = await fetch(`https://crm.familiaips.com/api/web/v1/recrm/create-contact-person`, {
        method: 'post',
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    })
        .then((response) => {
            //console.log(response.json())
            return response.json()
        })
        .catch((error) => {
            console.log(error)
            return {error: 'true'}
        });
    return resp;
    
}
export const UpdatingClient = async (params, method) => {
    //const params = 
    if (method === 'post')
        var data = params;
    if (!params.length)
        params = '';
    
    var resp = await fetch(`https://crm.familiaips.com/api/web/v1/recrm/update-client${params}`, {
        method: method ? method : 'get',
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('token')
        },
        [method === 'post' ? 'body' : '']: JSON.stringify(data)
        //{method ? body: JSON.stringify(params)}
        
    })
        .then((response) => {
            //console.log(response.json())
            return response.json()
        })
        .catch((error) => {
            console.log(error)
            return {error: 'true'}
        });
    return resp;
    
}

export const UploadAttach = async (data) => {
    var resp = await fetch(`https://crm.familiaips.com/api/web/v1/recrm/upload-attach`, {
        method: 'post',
        headers: {
            //"Content-Type": "multipart/form-data",
            "Authorization": "Bearer " + localStorage.getItem('token')
        },
        body: data
    })
        .then((response) => {
            return response.json()
        })
        .catch((error) => {
            console.log(error)
            return {error: 'true'}
        });
    return resp;
    
}
export const DeleteAttach = async (data) => {
    var resp = await fetch(`https://crm.familiaips.com/api/web/v1/recrm/delete-attach`, {
        method: 'post',
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    })
        .then((response) => {
            return response.json()
        })
        .catch((error) => {
            console.log(error)
            return {error: 'true'}
        });
    return resp;
    
}
export const VerifyClient = async (data) => {
    var resp = await fetch(`https://crm.familiaips.com/api/web/v1/recrm/verify-client`, {
        method: 'post',
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    })
        .then((response) => {
            return response.json()
        })
        .catch((error) => {
            console.log(error)
            return {error: 'true'}
        });
    return resp;
    
}
export const UpdateAttorney = async (data) => {
    var resp = await fetch(`https://crm.familiaips.com/api/web/v1/recrm/update-attorney`, {
        method: 'post',
        headers: {
            "Authorization": "Bearer " + localStorage.getItem('token')
        },
        body: data
    })
        .then((response) => {
            return response.json()
        })
        .catch((error) => {
            console.log(error)
            return {error: 'true'}
        });
    return resp;
    
}
export const UploadAttorneyHtm = async (data) => {
    var resp = await fetch(`https://crm.familiaips.com/api/web/v1/recrm/add-attorney-htm`, {
        method: 'post',
        headers: {
            "Authorization": "Bearer " + localStorage.getItem('token')
        },
        body: data
    })
        .then((response) => {
            return response.json()
        })
        .catch((error) => {
            console.log(error)
            return {error: 'true'}
        });
    return resp;
    
}
export const DeleteAttorneyHtm = async (data) => {
    var resp = await fetch(`https://crm.familiaips.com/api/web/v1/recrm/delete-attorney-htm`, {
        method: 'post',
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    })
        .then((response) => {
            return response.json()
        })
        .catch((error) => {
            console.log(error)
            return {error: 'true'}
        });
    return resp;
    
}
export const DeleteClient = async (id, userId) => {
    const data = {
        "id": id,
        "user_id": userId
    }
    console.log(data);
    var resp = await fetch(`https://crm.familiaips.com/api/web/v1/recrm/delete-client`, {
        method: 'post',
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    })
        .then((response) => {
            //console.log(response.json())
            return response.json()
        })
        .catch((error) => {
            console.log(error)
            return {error: 'true'}
        });
    return resp;
    
}
export const UpdatePoaSend = async (id, userId, status) => {
    const data = {
        "id": id,
        "user_id": userId,
        'status': status
    }
    console.log(data);
    var resp = await fetch(`https://crm.familiaips.com/api/web/v1/recrm/update-poa-send`, {
        method: 'post',
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    })
        .then((response) => {
            //console.log(response.json())
            return response.json()
        })
        .catch((error) => {
            console.log(error)
            return {error: 'true'}
        });
    return resp;
    
}
//${'?' + params && params}