import React, { useState, useEffect } from 'react';
import {FaBars, FaCircle, FaPhone, FaPhoneAlt, FaUser} from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';
import { Button } from '../ui/Button';
import { Select } from '../ui/Select';
import { AssignDriver } from '../../api/Shipment';
import { GetDrivers } from '../../api/User';
import { useNavigate } from "react-router-dom";
import { CreatingClient } from '../../api/Client';
import { InputWithLabel } from '../ui/InputWithLabel';



export const CreateClientModal = ({visible, setVisible, phone, search}) => {

    const [contactName, setContactName] = useState('');
    const [passportId, setPassportId] = useState('');
    const [phoneNumber, setPhoneNumber] = useState(phone ? phone : '');
    const [comment, setComment] = useState('');
    const [lang, setLang] = useState('');
    

    const createClient = async(e) => {
        e.preventDefault();
        const data = {
            contact_name: contactName,
            passport_id: passportId,
            phone_number: phoneNumber,
            comment: comment,
            lang: lang
        };
        var resp = await CreatingClient(data);
        console.log(resp);
        if (resp.status){
            search(phoneNumber);
            setVisible(false)
        }
        else
            console.log(resp);
    }

    if (visible == false)
        return null;

    return (
        <div onClick={() => setVisible(false)} className='modal'>
            <div onClick={(e) => {e.stopPropagation()}} className='modal-container-create-client'>
                <div className='driver-picker'>
                    <form onSubmit={createClient}>
                        <Select
                            data={Object.entries({
                                ''  : 'Select language',
                                'ru': 'Russian',
                                'ro': 'Romanian',
                                'en': 'English',
                                'he': 'Hebrew',
                                'ka': 'Georgian',
                            })}
                            label='Preffered Language'
                            placeholderFirst={true}
                            value={lang}
                            onChange={setLang}
                        />
                        <InputWithLabel
                            value={contactName}
                            onChange={setContactName}
                            label='Contact Name'
                        />
                        <InputWithLabel
                            value={passportId}
                            onChange={setPassportId}
                            label='Passport ID'
                        />
                        <InputWithLabel
                            value={phoneNumber}
                            onChange={setPhoneNumber}
                            label='Phone Number'
                        />
                        <InputWithLabel
                            value={comment}
                            onChange={setComment}
                            label='Comment'
                        />
                        <Button type='submit' className='btn bg-green'>
                            Create
                        </Button>
                    </form>
                </div>
            </div>
        </div>
    );
}